import styled from "styled-components";
import { hideVisually } from "polished";

export const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 208px;
  height: 44px;
  vertical-align: top;
  cursor: pointer;
`;

export const Input = styled.input.attrs({
  type: "file"
})`
  ${hideVisually};
`;

export const Custom = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 44px;
  padding: 0 12px;
  border-radius: 5px;
  font-family: ${props => props.theme.fonts.sans};
  font-size: 16px;
  line-height: 44px;
  color: ${props => props.theme.colors.text.default};
  background-color: ${props => props.theme.colors.bg.default};
  box-shadow: inset 0 0 0 2px ${props => props.theme.colors.bg.alt};
  user-select: none;

  &::before {
    content: "Choose file...";
  }
  &::after {
    content: "Browse";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    display: block;
    height: 44px;
    padding: 0 12px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: ${props => props.theme.fonts.sans};
    font-size: 16px;
    line-height: 44px;
    color: ${props => props.theme.colors.text.default};
    background-color: ${props => props.theme.colors.bg.alt};
  }

  ${Input}:focus ~ &::after {
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.brand.blue};
  }
`;
