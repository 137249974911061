import React, { useState } from "react";
import AriaModal from "react-aria-modal";
import { mdiClose } from "@mdi/js";

import Icon from "../icon";

function Panel({ children, trigger, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [entered, setEntered] = useState(false);

  function closeMenu() {
    setEntered(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 333);
  }
  return (
    <>
      <AriaModal
        titleText="modal"
        onExit={() => {
          closeMenu();
        }}
        onEnter={() => {
          setEntered(true);
        }}
        underlayStyle={{
          backgroundColor: entered ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
          transition: "all 300ms ease-in-out",
          zIndex: 9999
        }}
        mounted={isOpen}
        dialogStyle={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          minHeight: "100%",
          pointerEvents: "none"
        }}
      >
        <div
          className={entered ? "entered" : ""}
          css={`
            position: absolute;
            top: 0;
            bottom: 0;
            left 0;
            width: 100%;
            max-width: 768px;
            background-color: ${props => props.theme.colors.bg.default};
            overflow: hidden;
            pointer-events: all;
            transform: translate3d(-100%, 0, 0);
            transition: transform 300ms ease-in-out;

            &.entered {
              transform: translate3d(0, 0, 0);
            }
          `}
        >
          <button
            onClick={() => {
              closeMenu();
            }}
            css={`
              position: absolute;
              top: 16px;
              right: 16px;
              z-index: 1;
              width: 40px;
              height: 40px;
              margin: 0;
              padding: 0;
              border: 0;
              border-radius: 9999px;
              color: ${props => props.theme.colors.text.reverse};
              background: ${props => props.theme.colors.text.default};
              box-shadow: none;
              cursor: pointer;
              &:focus {
                outline: 0;
              }
            `}
          >
            <Icon
              symbol={mdiClose}
              size={1.5}
              color="currentColor"
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </button>
          {children}
        </div>
      </AriaModal>
      <div
        {...props}
        onClick={() => {
          setIsOpen(true);
        }}
        css={`
          // height: 100%;
        `}
      >
        {trigger}
      </div>
    </>
  );
}

export default Panel;
