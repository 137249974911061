import styled, { keyframes, createGlobalStyle } from "styled-components";
import { normalize } from "polished";

export const GlobalStyles = createGlobalStyle`
  ${normalize()};
  *, *::before, *::after { box-sizing: border-box; }
  html {
    font-family: ${props => props.theme.fonts.sans};
    font-size: 16px;
    font-feature-settings: "liga" 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    position: relative;
    width: 100%;
    margin: auto;
    background-color: #fff;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }
  95% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const fadeInNoTranslate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TransitionWrapper = styled.div`
  opacity: 0;

  @media print {
    opacity: 1;
  }

  &.mounting {
    animation: ${fadeIn} 500ms ${props => props.theme.easing} forwards;

    @supports (-moz-appearance: none) {
      animation: ${fadeInNoTranslate} 500ms ${props => props.theme.easing}
        forwards;
    }

    @supports (-ms-ime-align: auto) {
      animation: ${fadeInNoTranslate} 500ms ${props => props.theme.easing}
        forwards;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      animation: ${fadeInNoTranslate} 500ms ${props => props.theme.easing}
        forwards;
    }
  }
`;
