import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

import Text from "../text";

import { CustomInput, Submit, Wrapper, HiddenLabel } from "./style";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [failureMsg, setFailureMsg] = useState("");

  const [success, setSuccess] = useState("");

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        addToMailchimp(email) // listFields are optional if you are only capturing the email address.
          .then(data => {
            // I recommend setting data to React state
            // but you can do whatever you want (including ignoring this `then()` altogether)
            if (data.result === "success") {
              setSuccess(true);
            } else {
              setFailureMsg(
                "There was a problem submitting your email. You may already be subscribed. If this issue persists, please contact us."
              );
            }
          });
      }}
    >
      <Wrapper>
        {!success && (
          <>
            <HiddenLabel children={`Newsletter`} htmlFor="newsletter-input" />
            <CustomInput
              placeholder={`Your email`}
              value={email}
              id="newsletter-input"
              onChange={event => {
                setEmail(event.target.value);
              }}
            />
            <Submit children={`Submit`} type="submit" />
          </>
        )}
        {success && (
          <Text
            children={
              "Thanks for subscribing! Check your email for a confirmation."
            }
            p={4}
            color="text.reverse"
            fontSize={1}
          />
        )}
      </Wrapper>
      {failureMsg && (
        <Text color="text.reverse" fontSize={1} mt={2}>
          {failureMsg}
        </Text>
      )}
    </form>
  );
}

export default Newsletter;
