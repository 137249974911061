import React, { useEffect } from "react";
import styled from "styled-components";
import { color } from "styled-system";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { Box, H1, H6, Text } from "src/components";

const headerHeight = 160;
const tickerHeight = 24;

const Wrapper = styled.header`
  padding-top: ${tickerHeight + headerHeight + "px"};
  width: 100%;
  ${color};
  ${props => props.theme.mediaQueries.large} {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.75fr ${props => props.theme.space[8] + "px"} 1fr;
    grid-template-columns: 0.75fr ${props => props.theme.space[8] + "px"} 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
  }
`;

const halfWidthOfImage = (1280 / 2 - 80 / 2) / 2;

const Left = styled.section`
  margin: 0 ${props => props.theme.margin};
  padding: ${props => props.theme.space[8] + "px"} 0;
  ${props => props.theme.mediaQueries.large} {
    position: relative;
    z-index: 0;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    margin-right: 0;
    padding-top: ${props => props.theme.space[8] + "px"};
    padding-bottom: ${props => props.theme.space[8] + "px"};
    padding-left: calc(50vw - 640px - 7.5vw);
    &::after {
      content: "";
      position: absolute;
      top: ${"-" + (tickerHeight + headerHeight) + "px"};
      right: 50%;
      bottom: 0;
      left: -7.5vw;
      z-index: -1;
      background-color: ${props => props.theme.colors.bg.default};
      @media screen and (min-width: ${props => props.theme.maxWidth[3]}) {
        right: ${halfWidthOfImage + "px"};
        left: -7.5vw;
      }
    }
  }
`;

const Right = styled.section`
  margin: 0 ${props => props.theme.margin};
  padding-bottom: ${props => props.theme.space[8] + "px"};
  ${props => props.theme.mediaQueries.large} {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 1;
    grid-row: 1;
    margin-left: 0;
    padding-top: ${props => props.theme.space[10] + "px"};
    padding-right: calc(50vw - 640px - 7.5vw);
    padding-bottom: ${props => props.theme.space[10] + "px"};
  }
`;

function SplitHeader({
  color,
  invert,
  invertLogoOverride,
  left,
  right,
  setInvert,
  setInvertLogo
}) {
  useEffect(() => {
    if (invert) {
      setInvert(true);
      if (invertLogoOverride) {
        setInvertLogo(true);
      } else {
        let w = window,
          d = document,
          e = d.documentElement,
          g = d.getElementsByTagName("body")[0],
          x = w.innerWidth || e.clientWidth || g.clientWidth;
        if (x < 1024) {
          setInvertLogo(true);
        } else {
          setInvertLogo(false);
        }
      }
    }
  }, []);
  return (
    <Wrapper bg={color}>
      <Left>{left}</Left>
      <Right>{right}</Right>
    </Wrapper>
  );
}

export default SplitHeader;
