import styled from "styled-components";
import { hideVisually, rgba } from "polished";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.default};
`;

export const CustomInput = styled.input.attrs({ type: "email" })`
  flex: 1;
  min-width: 0;
  padding: 0 1em;
  border: 0;
  border-top-left-radius: 0.167em;
  border-bottom-left-radius: 0.167em;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: transparent;

  &::placeholder {
    color: #fff;
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
    background-color: ${props => rgba(props.theme.colors.bg.default, 0.125)};
  }
`;

export const Submit = styled.button`
  display: block;
  margin: 0;
  padding: ${(48 - 12) / 2 / 12}em 1.5em;
  border: 0;
  border-top-right-radius: 0.167em;
  border-bottom-right-radius: 0.167em;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.display};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.default};
  background-color: ${props => props.theme.colors.bg.default};
  cursor: pointer;
  appearance: none;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const HiddenLabel = styled.label`
  ${hideVisually()};
`;
