import React, { useState, useRef } from "react";
import AriaModal from "react-aria-modal";
import { mdiClose } from "@mdi/js";

import { H1 } from "../headings";
import LargeButton from "../largeButton";
import { Box } from "../globals";
import Loader from "../loader";
import Icon from "../icon";

function EmbedModal({ href, children, ...props }) {
  const modalRef = useRef(null);
  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [entered, setEntered] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  function closeMenu() {
    setEntered(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 333);
  }
  return (
    <>
      <AriaModal
        titleText="modal"
        onExit={() => {
          closeMenu();
        }}
        onEnter={() => {
          setEntered(true);
        }}
        underlayStyle={{
          backgroundColor: entered ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
          transition: "all 300ms ease-in-out",
          zIndex: 9999
        }}
        mounted={isOpen}
      >
        <div
          ref={wrapperRef}
          className={entered ? "entered" : ""}
          css={`
            top: 10vh;
            height: 80vh;
            width: 90vw;
            background: white;
            position: relative;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2.5rem;
            transform: scale3d(1.05, 1.05, 1);
            opacity: 0;
            transition: all 300ms ease-in-out;

            &.entered {
              opacity: 1;
              transform: scale3d(1, 1, 1);
            }

            @media (max-width: 900px) {
              top: 7.5vh;
              height: 92.5vh;
              width: 100vw;
            }
          `}
        >
          <LargeButton
            onClick={() => {
              closeMenu();
            }}
            css={`
              position: absolute;
              top: 5vh;
              right: 5%;
            `}
          >
            <Icon
              symbol={mdiClose}
              size={1.5}
              color="#000"
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </LargeButton>
          {showLoader && (
            <Box
              position="absolute"
              top="50%"
              left="50%"
              css={`
                transform: translate(-50%, -50%);
                z-index: 2;
              `}
            >
              <Loader />
            </Box>
          )}
          <iframe
            ref={modalRef}
            title="eventbrite"
            src={
              href && href.indexOf("eventbrite.ca/") !== -1
                ? "//eventbrite.ca/tickets-external?eid=" +
                  href.split("-")[href.split("-").length - 1]
                : href.indexOf("eventbrite.com/") !== -1
                ? "//eventbrite.com/tickets-external?eid=" +
                  href.split("-")[href.split("-").length - 1]
                : href
            }
            width="100%"
            height="100%"
            border="0"
            frameBorder="0"
            allowTransparency="true"
            css={`
              body {
                background: transparent;
              }
            `}
            onLoad={() => {
              setShowLoader(false);
              setTimeout(() => {
                wrapperRef.current.style.height =
                  modalRef.current.getBoundingClientRect().height + "px";
              }, 200);
            }}
          />
        </div>
      </AriaModal>
      <div
        {...props}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {children}
      </div>
    </>
  );
}

export default EmbedModal;
