import React from "react";
import { mdiBellOff, mdiBell, mdiPlus, mdiTrashCan } from "@mdi/js";

import Icon from "../../icon";

const Trigger = ({
  darkMode,
  added,
  remove,
  add,
  notified,
  toggleNotified
}) => (
  <div
    css={`
      margin-top: auto;
      padding-top: 8px;
    `}
  >
    <div
      css={`
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
      `}
      onClick={added ? remove : add}
    >
      <Icon
        symbol={added ? mdiTrashCan : mdiPlus}
        css={`
          vertical-align: top;
          fill: ${props => props.theme.colors.text.default};
        `}
      />
      <div
        css={`
          margin-left: 4px;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: ${darkMode ? "#fff" : ""};
        `}
      >
        {added ? "Remove" : "Add"}
      </div>
    </div>
    {added && (
      <div
        css={`
          position: absolute;
          bottom: 16px;
          right: 12px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          cursor: pointer;
          animation: shake-lr 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms
            both;

          @keyframes shake-lr {
            0%,
            100% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
            }
            10% {
              -webkit-transform: rotate(8deg);
              transform: rotate(8deg);
            }
            20%,
            40%,
            60% {
              -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
            }
            30%,
            50%,
            70% {
              -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
            }
            80% {
              -webkit-transform: rotate(-8deg);
              transform: rotate(-8deg);
            }
            90% {
              -webkit-transform: rotate(8deg);
              transform: rotate(8deg);
            }
          }
        `}
        onClick={toggleNotified}
      >
        <Icon
          symbol={notified ? mdiBell : mdiBellOff}
          color="white"
          css={`
            position: absolute;
            top: 50%;
            left: 50%;
            vertical-align: top;
            transform: translate(-50%, -50%);
          `}
        />
      </div>
    )}
  </div>
);

export default Trigger;
