import styled from "styled-components";
import { hideVisually, rgba } from "polished";

export const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: flex-start;
  width: max-content;
  border-radius: 2px;
  vertical-align: top;
  background-color: ${props => props.theme.colors.bg.wash};
  overflow: hidden;

  @media (max-width: 768px) {
    display: block;
    width: inherit;
    background-color: transparent;
  }
`;

export const ButtonWrapper = styled.label`
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;

  &:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

export const Input = styled.input.attrs({
  type: "radio"
})`
  ${hideVisually};
`;

export const Control = styled.div`
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: ${(44 - 12) / 2 / 12}em 1em;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.display};
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  color: ${props => rgba(props.theme.colors.text.default, 2 / 3)};
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  box-shadow: inset -1px 0 0 ${props => props.theme.colors.text.reverse},
    inset 1px 0 0 ${props => props.theme.colors.text.reverse};
  transition: color 500ms ${props => props.theme.easing};

  ${ButtonWrapper}:first-of-type & {
    box-shadow: inset -1px 0 0 ${props => props.theme.colors.text.reverse};
  }

  ${ButtonWrapper}:last-of-type & {
    box-shadow: inset 1px 0 0 ${props => props.theme.colors.text.reverse};
  }

  ${Input}:checked ~ & {
    color: ${props => props.theme.colors.text.reverse};
    color: #fff;
    @media (max-width: 768px) {
      background-color: ${props => props.theme.colors.brand.primary};
    }
  }

  @media (max-width: 768px) {
    background-color: ${props => props.theme.colors.bg.wash};
    box-shadow: inset -1px 0 0 ${props => props.theme.colors.text.reverse},
      inset 1px 0 0 ${props => props.theme.colors.text.reverse},
      inset 0 1px 0 0 ${props => props.theme.colors.text.reverse},
      inset 0 -1px 0 0 ${props => props.theme.colors.text.reverse} !important;
  }
`;

export const Slide = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 1px;
  height: 100%;
  background-color: ${props =>
    props.color ? props.color : props.theme.colors.brand.primary};
  transform-origin: left;
  transition: transform 500ms ${props => props.theme.easing};

  @media (max-width: 768px) {
    display: none;
  }
`;
