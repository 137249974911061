import React from "react";
import { Link } from "gatsby";
import AriaModal from "react-aria-modal";
import { mdiClose, mdiArrowLeft, mdiChevronRight } from "@mdi/js";
import styled, { keyframes } from "styled-components";

import { Box, FlexCol, FlexRow, H6, Text, PrismicLink } from "src/components";

import Icon from "../../icon";

const navEntrance = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const MobileNavItem = styled.div`
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  animation: ${navEntrance} 500ms ${(props) => props.theme.easing} forwards
    ${(props) => props.delay}ms;
`;

function MobileMenu({
  activeFestival,
  activeFestivalTitle,
  showATF,
  showPYF,
  showSchedule,
  showSponsors,
  showLineup,
  showFAQ,
  navigation,
  subnav,
  setSubnav,
  unmounting,
  close,
  primaryButtonLink,
  primaryButtonText,
  secondaryButtonLink,
  secondaryButtonText,
  invert,
  listenLink,
  playlistActive,
  setPlaylistActive,
}) {
  return (
    <>
      <AriaModal
        titleText="menuPanel"
        onExit={close}
        underlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          zIndex: 99999,
        }}
        dialogStyle={{
          right: 0,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          minHeight: "100%",
          pointerEvents: "none",
        }}
        mounted={true}
      >
        <div
          css={`
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            max-width: 256px;
            background-color: ${(props) => props.theme.colors.bg.default};
            overflow: hidden;
            pointer-events: all;
            animation: mountMenu 300ms ease-out forwards;

            &.unmounting {
              animation: unmountMenu 300ms ease-out forwards;
            }

            @keyframes mountMenu {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(100%);
              }
            }

            @keyframes unmountMenu {
              0% {
                transform: translateX(100%);
              }
              100% {
                transform: translateX(0);
              }
            }
          `}
          className={unmounting ? "unmounting" : ""}
        >
          <button
            onClick={close}
            css={`
              position: absolute;
              top: 16px;
              left: 16px;
              z-index: 1;
              width: 32px;
              height: 32px;
              margin: 0;
              padding: 0;
              border: 0;
              border-radius: 9999px;
              color: ${(props) => props.theme.colors.text.default};
              background: transparent;
              box-shadow: none;
              cursor: pointer;
              transform: scale(0);
              transition: transform 500ms ${(props) => props.theme.easing};

              &.open {
                transform: scale(1);
              }
            `}
            className={subnav.length === 0 ? "open" : ""}
          >
            <Icon
              symbol={mdiClose}
              size={1.5}
              color="currentColor"
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </button>
          <button
            onClick={() => {
              setSubnav([]);
            }}
            css={`
              position: absolute;
              top: 16px;
              left: 16px;
              z-index: 1;
              width: 32px;
              height: 32px;
              margin: 0;
              padding: 0;
              border: 0;
              border-radius: 9999px;
              color: ${(props) => props.theme.colors.text.default};
              background: transparent;
              box-shadow: none;
              cursor: pointer;
              cursor: pointer;
              transform: scale(0);
              transition: transform 500ms ${(props) => props.theme.easing};

              &.open {
                transform: scale(1);
              }
            `}
            className={subnav.length !== 0 ? "open" : ""}
          >
            <Icon
              symbol={mdiArrowLeft}
              size={1.5}
              color="currentColor"
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </button>
          <FlexCol height="100%">
            <div
              css={`
                margin-top: 64px;
              `}
            >
              {subnav.length === 0 && (
                <>
                  {activeFestival &&
                    activeFestival.ongoing_festival &&
                    activeFestival.ongoing_festival.uid && (
                      <MobileNavItem delay={0}>
                        <FlexRow
                          justifyContent="space-between"
                          alignItems="center"
                          px={4}
                          py={1}
                          onClick={() => {
                            if (subnav.length === 0) {
                              setSubnav([
                                showLineup && {
                                  label: "Lineup",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/lineup",
                                },
                                showSchedule && {
                                  label: "Schedule",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/schedule",
                                },
                                {
                                  label: "Tickets",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/tickets",
                                },
                                // TEMP CHANGE FOR SUMMER SERENADES
                                // showATF && {
                                //   label: "Map",
                                //   to:
                                //     "/" +
                                //     activeFestival.ongoing_festival.uid +
                                //     "/map",
                                // },
                                {
                                  label: "About",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/",
                                },
                                showATF && {
                                  label: "At The Festival",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/at-the-festival",
                                },
                                showPYF && {
                                  label: "Plan your Festival",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/plan",
                                },
                                // TEMP CHANGE FOR SUMMER SERENADES
                                // showPYF && {
                                //   label: "Accomodations",
                                //   to:
                                //     "/" +
                                //     activeFestival.ongoing_festival.uid +
                                //     "/plan#find-a-place-to-stay",
                                // },
                                showFAQ && {
                                  label: "FAQ",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/faq",
                                },
                                showSponsors && {
                                  label: "Sponsors",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/sponsors",
                                },
                                // TEMP CHANGE FOR SUMMER SERENADES
                                // {
                                //   label: "Past Lineups",
                                //   to: "/past-lineups/",
                                // },
                              ]);
                            } else {
                              setSubnav([]);
                            }
                          }}
                          css={`
                            position: relative;
                            z-index: 3;
                            pointer-events: all;
                            cursor: pointer;
                            &:focus {
                              outline: 0;
                            }
                          `}
                        >
                          <Text
                            fontSize={2}
                            fontWeight={600}
                            color="text.default"
                          >
                            {activeFestivalTitle}
                          </Text>

                          <Icon
                            symbol={mdiChevronRight}
                            css={`
                              width: 24px !important;
                              height: 24px !important;
                              margin-top: -2px;
                              margin-left: 1px;
                              vertical-align: middle;
                              fill: currentColor;
                              transform-origin: center;
                              transform: rotate(0);
                              transition: transform 250ms
                                ${(props) => props.theme.easing};
                            `}
                          />
                        </FlexRow>
                      </MobileNavItem>
                    )}

                  {navigation.map((navItem, index) => {
                    if (navItem.slice_type === "nav_item") {
                      return (
                        <MobileNavItem
                          key={"navItem" + index}
                          delay={(index + 1) * 50}
                        >
                          <FlexRow
                            justifyContent="space-between"
                            alignItems="center"
                            px={4}
                            py={1}
                            as={PrismicLink}
                            link={
                              navItem.primary.linked_page.type === "festival"
                                ? {
                                    link_type_override:
                                      navItem.primary.linked_page.uid,
                                    link_type: "Document",
                                  }
                                : navItem.primary.linked_page
                            }
                            onClick={
                              navItem.primary.linked_page.type ===
                              "get_involved"
                                ? () => {
                                    if (subnav.length === 0) {
                                      setSubnav([
                                        {
                                          label: "Volunteer",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/volunteer",
                                        },
                                        {
                                          label: "Sponsor",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/sponsor",
                                        },
                                        {
                                          label: "Artists",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/artists",
                                        },
                                        {
                                          label: "Vendors",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/vendors",
                                        },
                                        {
                                          label: "Donate",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/donate",
                                        },
                                        // {
                                        //   label: "Insiders' Club",
                                        //   to:
                                        //     "/" +
                                        //     navItem.primary.linked_page.slug +
                                        //     "/insiders-club",
                                        // },
                                        {
                                          label: "Media",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/media",
                                        },
                                      ]);
                                    } else {
                                      setSubnav([]);
                                    }
                                  }
                                : undefined
                            }
                            css={`
                              position: relative;
                              z-index: 3;
                              pointer-events: all;
                              cursor: pointer;
                              &:focus {
                                outline: 0;
                              }
                            `}
                          >
                            <Text
                              fontSize={2}
                              fontWeight={600}
                              color="text.default"
                            >
                              {navItem.primary.title}
                            </Text>
                            {navItem.primary.linked_page.type ===
                              "get_involved" && (
                              <Icon
                                symbol={mdiChevronRight}
                                css={`
                                  width: 24px !important;
                                  height: 24px !important;
                                  margin-top: -2px;
                                  margin-left: 1px;
                                  vertical-align: middle;
                                  fill: currentColor;
                                  transform-origin: center;
                                  transform: rotate(0);
                                  transition: transform 250ms
                                    ${(props) => props.theme.easing};
                                `}
                              />
                            )}
                          </FlexRow>
                        </MobileNavItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
              {subnav.length !== 0 && (
                <>
                  {subnav.map((subnavItem, index) => {
                    if (subnavItem.to) {
                      return (
                        <MobileNavItem
                          key={"navItem" + index}
                          delay={index * 50}
                        >
                          <FlexRow
                            px={4}
                            py={1}
                            key={"subnavItem" + index}
                            as={Link}
                            to={subnavItem.to}
                            style={{
                              zIndex: 3,
                              position: "relative",
                              pointerEvents: "all",
                              cursor: "pointer",
                            }}
                          >
                            <Text
                              fontSize={2}
                              fontWeight={600}
                              color="text.default"
                            >
                              {subnavItem.label}
                            </Text>
                          </FlexRow>
                        </MobileNavItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
            </div>
            <Box mt="auto">
              {listenLink && (
                <MobileNavItem
                  width={1}
                  onClick={() => {
                    setPlaylistActive(true);
                    close();
                  }}
                  delay={0}
                >
                  <FlexCol justifyContent="center" height="64px" px={4}>
                    <H6 children={`Listen`} color="text.default" />
                  </FlexCol>
                </MobileNavItem>
              )}
              {secondaryButtonText && secondaryButtonLink && (
                <MobileNavItem
                  width={1}
                  as={PrismicLink}
                  link={secondaryButtonLink}
                  delay={(navigation.length + 2) * 50}
                >
                  <FlexCol
                    justifyContent="center"
                    height="64px"
                    px={4}
                    bg="bg.wash"
                  >
                    <H6 children={secondaryButtonText} color="text.default" />
                  </FlexCol>
                </MobileNavItem>
              )}
              {primaryButtonText && primaryButtonLink && (
                <MobileNavItem
                  width={1}
                  as={PrismicLink}
                  link={primaryButtonLink}
                  delay={(navigation.length + 3) * 50}
                >
                  <FlexCol
                    justifyContent="center"
                    height="64px"
                    px={4}
                    bg="brand.primary"
                  >
                    <H6 children={primaryButtonText} color="text.reverse" />
                  </FlexCol>
                </MobileNavItem>
              )}
            </Box>
          </FlexCol>
        </div>
      </AriaModal>
    </>
  );
}

export default MobileMenu;
