import React, { useEffect, useState, useRef } from "react";

import { ButtonWrapper, Control, Input, Wrapper, Slide } from "./style";

const Button = ({ children, ...props }) => (
  <ButtonWrapper>
    <Input {...props} />
    <Control children={children} />
  </ButtonWrapper>
);

function RadioButton({
  color,
  options,
  onChange,
  value,
  valueProp,
  labelProp,
}) {
  const [uuid] = useState(require("uuid/v4"));
  const wrapperRef = useRef(null);
  const [width, changeWidth] = useState(0);
  const [position, changePosition] = useState(0);

  useEffect(() => {
    if (wrapperRef) {
      if (wrapperRef.current) {
        let found = -1;
        options.forEach((option, index) => {
          if (option) {
            if (
              valueProp ? option[valueProp] === value : option.option === value
            ) {
              found = index;
            }
          }
        });
        if (found !== -1) {
          changeWidth(
            Math.floor(
              wrapperRef.current.childNodes[found].getBoundingClientRect().width
            )
          );
          changePosition(wrapperRef.current.childNodes[found].offsetLeft);
        }
      }
    }
  }, [value]);

  return (
    <div>
      <Wrapper ref={wrapperRef}>
        {options.map((option, index) => {
          if (option) {
            return (
              <Button
                children={labelProp ? option[labelProp] : option.option}
                key={uuid + index}
                name={uuid}
                onClick={() => {
                  onChange(valueProp ? option[valueProp] : option.option);
                }}
                checked={
                  valueProp
                    ? option[valueProp] === value
                    : option.option === value
                }
              />
            );
          } else {
            return null;
          }
        })}
        <Slide
          style={{
            transform:
              "scale3d(" +
              width +
              ", 1, 1) translate3d(" +
              position / width +
              "px, 0, 0)",
          }}
          color={color}
        />
      </Wrapper>
    </div>
  );
}

export default RadioButton;
