import React from "react";

import { FlexCol, FlexGrid } from "../globals";
import { H3 } from "../headings";
import Text from "../text";

const ScheduleTable = ({ heading, items }) => (
  <>
    <div
      css={`
        padding: ${props => props.theme.space[7] + "px"}
          ${props => props.theme.margin};
        background-color: ${props => props.theme.colors.bg.reverse};
        ${props => props.theme.mediaQueries.medium} {
          padding: ${props => props.theme.space[7] + "px"};
        }
      `}
    >
      <FlexGrid gutterX={[5]} gutterY={[4]}>
        {heading && (
          <FlexCol width={[1, null, 1 / 3]}>
            <H3 children={heading} color="text.reverse" />
          </FlexCol>
        )}
        <FlexCol width={[1, null, 2 / 3]}>
          <FlexGrid gutterY={[2]}>
            {items &&
              items.map((item, index) => (
                <>
                  <div
                    key={item.day + index}
                    css={`
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      @media screen and (min-width: 32em) {
                        flex-direction: row;
                        > * {
                          width: 50%;
                        }
                      }
                    `}
                  >
                    <Text
                      children={item.label}
                      fontSize={[1, 2]}
                      color="text.reverseBody"
                    />
                    <Text
                      children={item.open + " – " + item.close}
                      fontSize={[1, 2]}
                      color="text.reverse"
                      css={`
                        font-variant-numeric: tabular-nums;
                      `}
                    />
                  </div>
                </>
              ))}
          </FlexGrid>
        </FlexCol>
      </FlexGrid>
    </div>
  </>
);

export default ScheduleTable;
