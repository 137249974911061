import React, { createContext, useContext, useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import moment from "moment-timezone";

export const ScheduleContext = createContext();

export function ScheduleProvider({ children }) {
  const [activeFestival, setActiveFestival] = useState(undefined);
  const [activeFestivalSchedule, setActiveFestivalSchedule] = useState(
    undefined
  );
  const [notificationsActive, setNotificationsActiveInner] = useState(true);
  const [needsPin, setNeedsPIN] = useState(false);
  const [retrieve, setRetrieve] = useState(false);
  const [create, setCreate] = useState(false);
  const [scheduleChecked, setScheduleChecked] = useState(false);
  const [schedule, setSchedule] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [instructionsComplete, setInstructionsComplete] = useState(false);

  useEffect(() => {
    if (activeFestival && localStorage) {
      let scheduleKey = localStorage.getItem("scheduleAccess");
      if (scheduleKey) {
        scheduleKey = JSON.parse(scheduleKey);
      }
      if (scheduleKey) {
        if (scheduleKey.pin) {
          setProcessing(true);
          fetch("/.netlify/functions/getSchedule", {
            method: "POST",
            body: JSON.stringify({
              activeFestival: activeFestival,
              phone: scheduleKey.phone,
              pin: scheduleKey.pin
            })
          })
            .then(results => {
              return results.json();
            })
            .then(({ success, schedule, notifications }) => {
              setProcessing(false);
              if (success) {
                setNotificationsActiveInner(notifications);
                setSchedule(formatSchedule(schedule));
                setInstructionsComplete(true);
                setScheduleChecked(true);
              } else {
                removeSchedule();
                setScheduleChecked(true);
              }
            });
        } else {
          if (
            !scheduleKey.expiry ||
            moment(scheduleKey.expiry)
              .tz("America/Edmonton")
              .unix() <
              moment()
                .tz("America/Edmonton")
                .unix()
          ) {
            setScheduleChecked(true);
            setNeedsPIN(true);
          } else {
            removeSchedule();
          }
        }
      } else {
        setScheduleChecked(true);
      }
    }
  }, [activeFestival]);

  const formatSchedule = schedule => {
    let scheduleFormatted = [];
    schedule.forEach(userScheduleItem => {
      activeFestivalSchedule.forEach(item => {
        if (item.primary.stage.id && item.primary.start_time) {
          if (
            item.primary.stage.id +
              moment(item.primary.start_time)
                .tz("America/Edmonton")
                .unix() ===
              userScheduleItem ||
            item.primary.stage.id +
              moment(item.primary.start_time)
                .tz("America/Edmonton")
                .unix() +
              "notificationsActive" ===
              userScheduleItem
          ) {
            scheduleFormatted.push({
              ...item,
              notify:
                userScheduleItem.indexOf("notificationsActive") !== -1
                  ? true
                  : false
            });
          }
        }
      });
    });
    scheduleFormatted = scheduleFormatted.sort((a, b) => {
      if (
        (a.primary.start_time &&
          moment(a.primary.start_time)
            .tz("America/Edmonton")
            .unix()) <
        (b.primary.start_time &&
          moment(b.primary.start_time)
            .tz("America/Edmonton")
            .unix())
      )
        return -1;
      if (
        (a.primary.start_time &&
          moment(a.primary.start_time)
            .tz("America/Edmonton")
            .unix()) >
        (b.primary.start_time &&
          moment(b.primary.start_time)
            .tz("America/Edmonton")
            .unix())
      )
        return 1;
      return 0;
    });
    return scheduleFormatted;
  };

  const getSchedule = (pin, errorCallback) => {
    if (localStorage) {
      let scheduleKey = localStorage.getItem("scheduleAccess");
      if (scheduleKey) {
        scheduleKey = JSON.parse(scheduleKey);
      }
      if (scheduleKey.phone) {
        setProcessing(true);
        fetch("/.netlify/functions/getSchedule", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            activeFestival: activeFestival,
            phone: scheduleKey.phone,
            pin: pin
          })
        })
          .then(results => {
            return results.json();
          })
          .then(({ success, schedule, notifications, error }) => {
            setProcessing(false);
            if (success) {
              localStorage.setItem(
                "scheduleAccess",
                JSON.stringify({ phone: scheduleKey.phone, pin: pin })
              );
              setNotificationsActiveInner(notifications);
              setSchedule(formatSchedule(schedule));
              setRetrieve(false);
              setNeedsPIN(false);
            } else {
              if (errorCallback) {
                errorCallback({ error });
              }
            }
          });
      }
    }
  };

  const startSchedule = (
    phoneNumber,
    item,
    marketingConsent,
    sendText,
    callback,
    errorCallback
  ) => {
    let scheduleKey = localStorage.getItem("scheduleAccess");
    if (scheduleKey) {
      scheduleKey = JSON.parse(scheduleKey);
    }
    setProcessing(true);
    fetch("/.netlify/functions/createSchedule", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        activeFestival: activeFestival,
        item: item,
        phone: phoneNumber ? phoneNumber : scheduleKey && scheduleKey.phone,
        marketingConsent: marketingConsent,
        notifications: true,
        sendText: sendText
      })
    })
      .then(results => {
        return results.json();
      })
      .then(({ success, error }) => {
        setProcessing(false);
        if (success) {
          localStorage.setItem(
            "scheduleAccess",
            JSON.stringify({
              phone: phoneNumber
                ? phoneNumber
                : scheduleKey && scheduleKey.phone,
              expiry: moment()
                .add(5, "m")
                .toDate()
            })
          );
          setNeedsPIN(true);
          if (callback) {
            callback();
          }
        } else {
          if (errorCallback) {
            errorCallback({ error });
          }
        }
      });
  };

  const updateSchedule = (newSchedule, newNotifications) => {
    if (localStorage) {
      let scheduleKey = localStorage.getItem("scheduleAccess");
      if (scheduleKey) {
        scheduleKey = JSON.parse(scheduleKey);
      }
      let flatSchedule = [];
      newSchedule.forEach(item => {
        flatSchedule.push(
          item.primary.stage.id +
            moment(item.primary.start_time)
              .tz("America/Edmonton")
              .unix() +
            (item.notify ? "notificationsActive" : "")
        );
      });
      if (scheduleKey.phone) {
        fetch("/.netlify/functions/editSchedule", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            activeFestival: activeFestival,
            phone: scheduleKey.phone,
            pin: scheduleKey.pin,
            notifications: newNotifications,
            schedule: flatSchedule
          })
        })
          .then(results => {
            return results.json();
          })
          .then(({ success, schedule, error }) => {
            // if (success) {
            //   setSchedule(formatSchedule(schedule));
            // }
          });
      }
    }
  };

  const addToSchedule = scheduleItem => {
    let newSchedule = JSON.parse(JSON.stringify(schedule));
    newSchedule.push(scheduleItem);
    newSchedule = newSchedule.sort((a, b) => {
      if (
        (a.primary.start_time &&
          moment(a.primary.start_time)
            .tz("America/Edmonton")
            .unix()) <
        (b.primary.start_time &&
          moment(b.primary.start_time)
            .tz("America/Edmonton")
            .unix())
      )
        return -1;
      if (
        (a.primary.start_time &&
          moment(a.primary.start_time)
            .tz("America/Edmonton")
            .unix()) >
        (b.primary.start_time &&
          moment(b.primary.start_time)
            .tz("America/Edmonton")
            .unix())
      )
        return 1;
      return 0;
    });
    setSchedule(newSchedule);
    updateSchedule(newSchedule);
  };

  const removeFromSchedule = scheduleItem => {
    let index = isInSchedule(scheduleItem);
    if (index !== -1) {
      let newSchedule = JSON.parse(JSON.stringify(schedule));
      newSchedule.splice(index, 1);
      setSchedule(newSchedule);
      updateSchedule(newSchedule);
    }
  };
  const isInSchedule = scheduleItem => {
    let isPresent = -1;
    if (schedule) {
      schedule.forEach((existingItem, index) => {
        if (existingItem.primary.stage && scheduleItem.primary.stage) {
          if (
            existingItem.primary.stage.id +
              moment(existingItem.primary.start_time)
                .tz("America/Edmonton")
                .unix() ===
            scheduleItem.primary.stage.id +
              moment(scheduleItem.primary.start_time)
                .tz("America/Edmonton")
                .unix()
          ) {
            isPresent = index;
          }
        }
      });
    }
    return isPresent;
  };

  const removeSchedule = () => {
    localStorage.removeItem("scheduleAccess");
    setSchedule(undefined);
  };

  const setNotificationsActive = value => {
    setNotificationsActiveInner(value);
    updateSchedule(schedule, value);
  };

  // const toggleNotified = scheduleItem => {
  //   let index = isInSchedule(scheduleItem);
  //   if (index !== -1) {
  //     let newSchedule = JSON.parse(JSON.stringify(schedule));
  //     newSchedule[index].notify = newSchedule[index].notify ? false : true;
  //     setSchedule(newSchedule);
  //     updateSchedule(newSchedule);
  //   }
  // };

  // const isNotified = scheduleItem => {
  //   let willNotify = false;
  //   if (schedule) {
  //     schedule.forEach((existingItem, index) => {
  //       if (existingItem.primary.stage && scheduleItem.primary.stage) {
  //         if (
  //           existingItem.primary.stage.id +
  //             moment(existingItem.primary.start_time).unix() ===
  //             scheduleItem.primary.stage.id +
  //               moment(scheduleItem.primary.start_time).unix() &&
  //           existingItem.notify
  //         ) {
  //           willNotify = true;
  //         }
  //       }
  //     });
  //   }
  //   return willNotify;
  // };

  return (
    <StaticQuery
      query={graphql`
        {
          activeFestival: prismicActiveFestival {
            dataString
          }
          festivals: allPrismicFestival {
            edges {
              node {
                uid
                dataString
              }
            }
          }
          stages: allPrismicStage {
            edges {
              node {
                prismicId
                dataString
              }
            }
          }
          artists: allPrismicArtist {
            edges {
              node {
                uid
                prismicId
                dataString
              }
            }
          }
        }
      `}
      render={data => {
        let artists = [];
        if (data.artists) {
          data.artists.edges.forEach(artist => {
            artists.push({
              ...JSON.parse(artist.node.dataString),
              uid: artist.node.uid,
              id: artist.node.prismicId
            });
          });
        }
        let activeFestivalData = {};
        if (data.activeFestival) {
          activeFestivalData = JSON.parse(data.activeFestival.dataString);
        }
        if (data.festivals && !activeFestival && !activeFestivalSchedule) {
          data.festivals.edges.forEach(festival => {
            let festivalParsed = {
              ...JSON.parse(festival.node.dataString),
              uid: festival.node.uid
            };
            if (
              festivalParsed.uid === activeFestivalData.ongoing_festival.uid
            ) {
              setActiveFestival(
                festivalParsed.uid +
                  moment(festivalParsed.start_day).format("YYYY")
              );
              let intermediateSchedule = [];
              festivalParsed.schedule.forEach(scheduleItem => {
                if (scheduleItem.primary.start_time) {
                  if (scheduleItem.primary.stage) {
                    data.stages.edges.forEach(stage => {
                      if (
                        stage.node.prismicId === scheduleItem.primary.stage.id
                      ) {
                        scheduleItem.stage = JSON.parse(stage.node.dataString);
                      }
                    });
                  }
                  if (scheduleItem.primary.performer) {
                    artists.forEach(artist => {
                      if (artist.id === scheduleItem.primary.performer.id) {
                        scheduleItem.performerData = artist;
                      }
                    });
                  }
                  if (scheduleItem.items && scheduleItem.items[0].artist) {
                    scheduleItem.performerData = [];
                    scheduleItem.items.forEach(itemArtist => {
                      artists.forEach(artist => {
                        if (artist.id === itemArtist.artist.id) {
                          scheduleItem.performerData.push(artist);
                        }
                      });
                    });
                  }
                  intermediateSchedule.push(scheduleItem);
                }
              });
              setActiveFestivalSchedule(intermediateSchedule);
            }
          });
        }
        return (
          <ScheduleContext.Provider
            value={{
              processing: processing,
              activeFestival: activeFestival,
              needsPin: needsPin,
              setNeedsPIN: setNeedsPIN,
              retrieve: retrieve,
              setRetrieve: setRetrieve,
              schedule: schedule,
              scheduleChecked: scheduleChecked,
              startSchedule: startSchedule,
              addToSchedule: addToSchedule,
              getSchedule: getSchedule,
              isInSchedule: isInSchedule,
              removeFromSchedule: removeFromSchedule,
              removeSchedule: removeSchedule,
              create: create,
              setCreate: setCreate,
              instructionsComplete: instructionsComplete,
              setInstructionsComplete: setInstructionsComplete,
              notificationsActive: notificationsActive,
              setNotificationsActive: setNotificationsActive
              // toggleNotified: toggleNotified,
              // isNotified: isNotified,
            }}
          >
            {children}
          </ScheduleContext.Provider>
        );
      }}
    />
  );
}

export const useSchedule = () => useContext(ScheduleContext);
