import React from "react";
import { Link } from "gatsby";

import { Box, FlexGrid, H3, SectionWrapper } from "src/components";

import { SubnavWrapper, SubnavCover } from "../style";

function Subnav({ headerState, subnav, setSubnav }) {
  return (
    <>
      <SubnavWrapper
        className={subnav.length > 0 ? "show " + headerState : headerState}
      >
        <SectionWrapper maxWidth={2}>
          <Box py={[7, 9]}>
            <FlexGrid gutterX={[5]} gutterY={[3]}>
              {subnav.map((nav, index) => {
                if (nav.to) {
                  return (
                    <Box
                      width={1 / 2}
                      alignItems="center"
                      key={"subnav" + index}
                    >
                      <H3
                        color="text.reverse"
                        style={{
                          letterSpacing: "0.025em",
                          textTransform: "uppercase"
                        }}
                      >
                        <Link
                          to={nav.to}
                          css={`
                            display: inline-block;
                            overflow: hidden;
                          `}
                        >
                          <span
                            css={`
                              display: inline-block;
                              transform: translateX(-1em);
                              transition: transform 350ms
                                ${props => props.theme.easing};

                              @media not all and (hover: none) {
                                &:hover {
                                  transform: translateX(0);

                                  &::before {
                                    opacity: 0.5;
                                    transform: translateX(0);
                                  }
                                }
                              }

                              &::before {
                                content: "→";
                                display: inline-block;
                                padding-right: 0.25em;
                                opacity: 0;
                                transform: translateX(-100%);
                                transition: opacity 350ms
                                    ${props => props.theme.easing},
                                  transform 350ms ${props => props.theme.easing};
                              }
                            `}
                          >
                            {nav.label}
                          </span>
                        </Link>
                      </H3>
                    </Box>
                  );
                } else {
                  return null;
                }
              })}
            </FlexGrid>
          </Box>
        </SectionWrapper>
      </SubnavWrapper>
      <SubnavCover
        onClick={() => setSubnav([])}
        className={subnav.length > 0 ? "show" : ""}
      />
    </>
  );
}

export default Subnav;
