import styled, { css } from "styled-components";
import { width } from "styled-system";
import { cover } from "polished";

import { buttonStyles } from "src/utils";

const height = "32px";
const radii = "2px";

export const Wrapper = styled.button`
  position: relative;
  z-index: 0;
  display: inline-block;
  ${width};
  height: ${height};
  margin: 0;
  padding: 0 16px;
  border: 0;
  border-radius: ${radii};
  font-family: ${props => props.theme.fonts.display};
  font-size: 14px;
  font-weight: 600;
  line-height: ${height};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  ${buttonStyles};
  cursor: pointer;
  appearance: none;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > span {
    position: relative;
    z-index: 1;
    display: inherit;
    transition: transform 250ms ${props => props.theme.easing};
  }

  &::before,
  &::after {
    content: "";
    ${cover()};
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: ${radii};
    transition: transform 250ms ${props => props.theme.easing};
  }

  &::after {
    z-index: -1;
  }

  @media not all and (hover: none) {
    &:hover {
      > span {
        transform: translate3d(3px, -3px, 0);
      }
      > svg {
        transform: translate3d(3px, calc(-3px + -50%), 0);
      }
      &::before {
        transform: translate3d(3px, -3px, 0);
      }
    }
  }
  &:active {
    > span {
      transform: translate3d(1.5px, -1.5px, 0);
    }
    > svg {
      transform: translate3d(1.5px, calc(-1.5px + -50%), 0);
    }
    &::before {
      transform: translate3d(1.5px, -1.5px, 0);
    }
  }

  > svg {
    position: absolute;
    top: 50%;

    width: ${16 / 14}em !important;
    height: ${16 / 14}em !important;

    fill: currentColor;

    transform: translateY(-50%);
    pointer-events: none;
    transition: transform 250ms ${props => props.theme.easing};
  }

  ${props =>
    props.iconLeft &&
    css`
      padding-left: calc(1.25em + 1.5em);

      > svg:first-of-type {
        left: 1em;
      }
    `};

  ${props =>
    props.iconRight &&
    css`
      padding-right: calc(1.25em + 1.5em);

      > svg:last-of-type {
        right: 1em;
      }
    `};
`;
