import React from "react";

import Icon from "../icon";

import { Wrapper } from "./style";

const IconButton = ({ icon, variant, bg, color, ...props }) => (
  <Wrapper icon={icon} variant={variant} {...props} bg={bg} color={color}>
    <Icon symbol={icon} size={""} />
  </Wrapper>
);

IconButton.defaultProps = {
  variant: "default"
};

export default IconButton;
