import styled, { css } from "styled-components";
import { rgba } from "polished";

export const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  vertical-align: top;
  user-select: none;

  svg {
    position: absolute;
    top: 50%;
    width: 1.25em !important;
    height: 1.25em !important;
    fill: ${props => props.theme.colors.text.alt};
    transform: translateY(-50%);
    pointer-events: none;
  }

  ${props =>
    props.iconLeft &&
    css`
      svg:first-of-type {
        left: 0.5em;
      }
    `};

  ${props =>
    props.iconRight &&
    css`
      svg:last-of-type {
        right: 0.5em;
      }
    `};
`;

export const Label = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  color: ${props => rgba(props.theme.colors.text.default, 0.5)};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

export const Comp = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: 8px 12px;
  border: 0;
  border-radius: 2px;
  outline: 0;
  font-size: 16px;
  line-height: 1.5;
  color: ${props => props.theme.colors.text.default};
  background-color: ${props => props.theme.colors.bg.default};
  box-shadow: inset 0 0 0 2px
    ${props => rgba(props.theme.colors.bg.reverse, 0.5)};
  appearance: none;

  &:focus {
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.brand.primary};
  }
  &:active {
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.brand.primary};
  }
  &::placeholder {
    color: ${props => props.theme.colors.text.default};
    opacity: 0.5;
  }

  ${props =>
    props.iconLeft &&
    css`
      padding-left: 2em;
    `};

  ${props =>
    props.iconRight &&
    css`
      padding-right: 2em;
    `};

  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
