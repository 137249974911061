import React from "react";

import { Wrapper, Input, Control } from "./style";

const Checkbox = ({ color, children, ...props }) => (
  <Wrapper color={color}>
    <Input {...props} />
    <Control />
    {children}
  </Wrapper>
);

export default Checkbox;
