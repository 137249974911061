import React, { useState } from "react";
import AriaModal from "react-aria-modal";
import { mdiClose } from "@mdi/js";

import LargeButton from "../largeButton";
import Icon from "../icon";

function EmbedModal({ children, trigger, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [entered, setEntered] = useState(false);

  function closeMenu() {
    setEntered(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 333);
  }
  return (
    <>
      <AriaModal
        titleText="modal"
        onExit={() => {
          closeMenu();
        }}
        onEnter={() => {
          setEntered(true);
        }}
        underlayStyle={{
          backgroundColor: entered ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
          transition: "all 300ms ease-in-out",
          zIndex: 9999
        }}
        mounted={isOpen}
        verticallyCenter={true}
      >
        <div
          className={entered ? "entered" : ""}
          css={`
            position: relative;
            width: 90vw;
            max-width: 1024px;
            background: white;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2.5rem;
            transform: scale3d(1.05, 1.05, 1);
            opacity: 0;
            transition: all 300ms ease-in-out;

            &.entered {
              opacity: 1;
              transform: scale3d(1, 1, 1);
            }

            @media (max-width: 900px) {
              width: 100vw;
            }
          `}
        >
          <LargeButton
            onClick={() => {
              closeMenu();
            }}
            css={`
              position: absolute;
              top: 5%;
              right: 5%;
              z-index: 30;
            `}
          >
            <Icon
              symbol={mdiClose}
              size={1.5}
              color="#000"
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </LargeButton>
          {React.cloneElement(children, {
            close: () => {
              closeMenu();
            }
          })}
        </div>
      </AriaModal>
      <div
        {...props}
        onClick={() => {
          setIsOpen(true);
        }}
        css={`
          // height: 100%;
        `}
      >
        {trigger}
      </div>
    </>
  );
}

export default EmbedModal;
