import { darken, lighten, rgba } from "polished";

function Theme({ bg, text, primary, secondary, tertiary }) {
  return {
    mediaQueries: {
      small: `@media screen and (min-width: 40em)`,
      medium: `@media screen and (min-width: 52em)`,
      large: `@media screen and (min-width: 64em)`
    },
    colors: {
      text: text
        ? text
        : {
            default: "#1B1A19",
            body: rgba("#1B1A19", 0.75),
            alt: rgba("#1B1A19", 0.5),
            reverse: "#FFF",
            reverseBody: rgba("#FFF", 0.75),
            reverseAlt: rgba("#FFF", 0.5)
          },
      bg: bg
        ? bg
        : {
            default: "#FFF",
            alt: "#d8d8d8",
            wash: "#e6e6e6",
            reverse: "#1B1A19"
          },
      brand: {
        primary: primary ? primary : "#D55C57",
        secondary: secondary
          ? secondary
          : primary
          ? darken(0.1, primary)
          : "#1B1A19",
        tertiary: tertiary
          ? tertiary
          : primary
          ? lighten(0.1, primary)
          : "#FFFFFF",
        altPrimary: "#F16250",
        altSecondary: "#B9CFCB",
        altTertiary: "#F3C78B"
      }
    },
    space: [0, 4, 8, 16, 24, 32, 48, 64, 80, 96, 128, 160, 192, 256],
    maxWidth: ["640px", "960px", "1280px"],
    margin: "7.5vw",
    fontSizes: [14, 16, 18, 24, 36, 48],
    fonts: {
      display: `'Brandon Text'`,
      sans: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`
    },
    easing: `cubic-bezier(0.215, 0.61, 0.355, 1)`
  };
}

export default Theme;
