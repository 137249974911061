const linkResolver = function (doc) {
  if (doc.type === "festival") {
    return "/" + doc.uid + "/tickets";
  }
  if (doc.type === "concer") {
    return "/concert/" + doc.uid;
  }
  if (
    doc.type === "post" ||
    doc.type === "announcement" ||
    doc.type === "event"
  ) {
    return "/" + doc.type + "/" + doc.uid;
  } else {
    return "/" + doc.type;
  }
};
export default linkResolver;
