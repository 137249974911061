import React from "react";
import PropTypes from "prop-types";

import { Box, FlexGrid, H2, RichText } from "src/components";

const SectionHeader = ({ body, heading, id, invert }) => (
  <Box
    as="header"
    id={id}
    mb={[6, 7, 8]}
    css={`
      overflow: hidden;
    `}
  >
    <FlexGrid as="section" gutterX={[7]} gutterY={[3]}>
      {heading && (
        <Box width={[1, 1, 1 / 2]}>
          <H2
            children={heading}
            color={invert ? "text.reverse" : "text.default"}
          />
        </Box>
      )}
      {body && (
        <Box width={[1, 1, 1 / 2]}>
          <RichText
            children={body}
            fontSize={[1, 2, 3]}
            color={invert ? "text.reverseBody" : "text.body"}
          />
        </Box>
      )}
    </FlexGrid>
  </Box>
);

SectionHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string,
  invert: PropTypes.bool.isRequired
};

export default SectionHeader;
