import React from "react";
import { Link } from "gatsby";
import { mdiEye, mdiTrashCan, mdiPlus } from "@mdi/js";

import Icon from "../../icon";

function Added({ darkMode, added, remove, add, view, emphasized }) {
  return (
    <div
      css={`
        margin-top: auto;
        padding-top: 8px;
      `}
    >
      <div
        css={`
          display: inline-block;
        `}
      >
        <div
          css={`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            user-select: none;
            cursor: pointer;
          `}
          style={
            emphasized && {
              borderRadius: 16,
              background: "#1B1A19",
              padding: 12
            }
          }
          onClick={added ? remove : add}
        >
          <div
            css={`
              position: relative;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #fff;
            `}
          >
            <Icon
              symbol={added ? mdiTrashCan : mdiPlus}
              color={darkMode ? "#1B1A19" : "#fff"}
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </div>
          <div
            css={`
              margin-left: 8px;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.05em;
              color: ${darkMode || emphasized ? "#fff" : "#1B1A19"};
            `}
          >
            {added ? "Remove" : "Add"}
          </div>
        </div>
      </div>
      {view && (
        <Link
          css={`
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin-top: 24px;
          `}
          to={view}
        >
          <div
            css={`
              position: relative;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #fff;
            `}
          >
            <Icon
              symbol={mdiEye}
              color={emphasized ? "#fff" : "#1B1A19"}
              css={`
                position: absolute;
                top: 50%;
                left: 50%;
                vertical-align: top;
                transform: translate(-50%, -50%);
              `}
            />
          </div>
          <div
            css={`
              color: ${darkMode ? "#fff" : ""};
              margin-left: 8px;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.05em;
            `}
            children={"View Schedule"}
          />
        </Link>
      )}
      {/* {added && (
        <div
          css={`
            position: absolute;
            bottom: ${emphasized ? 6 : 0}px;
            right: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            cursor: pointer;
            animation: shake-lr 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
              500ms both;

            @keyframes shake-lr {
              0%,
              100% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
              }
              10% {
                -webkit-transform: rotate(8deg);
                transform: rotate(8deg);
              }
              20%,
              40%,
              60% {
                -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
              }
              30%,
              50%,
              70% {
                -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
              }
              80% {
                -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg);
              }
              90% {
                -webkit-transform: rotate(8deg);
                transform: rotate(8deg);
              }
            }
          `}
          onClick={toggleNotified}
          style={
            emphasized && {
              borderRadius: 9999,
              background: "#1B1A19",
              height: 32,
              width: 32
            }
          }
        >
          <Icon
            symbol={notified ? mdiBell : mdiBellOff}
            color={emphasized ? "#fff" : "#1B1A19"}
            css={`
              position: absolute;
              top: 50%;
              left: 50%;
              vertical-align: top;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      )}*/}
    </div>
  );
}

export default Added;
