import React from "react";
import PropTypes from "prop-types";

import { Button, H3, SectionWrapper, Text, PrismicLink } from "src/components";

const RibbonCTA = ({
  color,
  body,
  buttonText,
  buttonLink,
  heading,
  id,
  invert
}) => (
  <SectionWrapper as="aside" id={id} py={[8, 9, 10]} bg={color}>
    <div
      css={`
        display: flex;
        flex-direction: column;
        ${props => props.theme.mediaQueries.medium} {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      `}
    >
      <div
        css={`
          margin-bottom: ${props => props.theme.space[5] + "px"};
          ${props => props.theme.mediaQueries.medium} {
            flex: 1;
            margin-right: ${props => props.theme.space[7] + "px"};
            margin-bottom: 0;
          }
        `}
      >
        <H3
          children={heading}
          color={invert ? "text.reverse" : "text.default"}
        />
        {body && (
          <Text
            children={body}
            mt={3}
            color={invert ? "text.reverse" : "text.default"}
          />
        )}
      </div>
      {buttonText && buttonLink && (
        <div>
          <Button
            as={PrismicLink}
            children={buttonText}
            link={buttonLink}
            variant={invert ? "reverseAlt" : "defaultAlt"}
          />
        </div>
      )}
    </div>
  </SectionWrapper>
);

RibbonCTA.propTypes = {
  color: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string,
  id: PropTypes.string,
  invert: PropTypes.bool.isRequired
};

export default RibbonCTA;
