import styled, { css } from "styled-components";
import { stripUnit } from "polished";
import {
  style,
  position,
  top,
  right,
  bottom,
  left,
  zIndex,
  display,
  flex,
  flexDirection,
  flexBasis,
  flexWrap,
  justifyContent,
  alignContent,
  alignItems,
  justifySelf,
  alignSelf,
  order,
  gridTemplateColumns,
  gridTemplateRows,
  gridAutoColumns,
  gridAutoRows,
  gridAutoFlow,
  gridColumn,
  gridRow,
  gridGap,
  gridColumnGap,
  gridRowGap,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  size,
  space,
  fontFamily,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  color,
  background,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  backgroundRepeat,
  borders,
  borderRadius,
  borderColor,
  boxShadow
} from "styled-system";

const CoreProps = css`
  ${space};
  ${width};
  ${fontSize};
  ${color};
`;

const PositioningProps = css`
  ${position};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${zIndex};
`;

const BoxModelProps = css`
  ${display};
  ${minWidth};
  ${maxWidth};
  ${height};
  ${minHeight};
  ${maxHeight};
  ${size};
`;

const FlexboxProps = css`
  ${flex};
  ${flexDirection};
  ${flexBasis};
  ${flexWrap};
  ${justifyContent};
  ${alignContent};
  ${alignItems};
  ${justifySelf};
  ${alignSelf};
  ${order};
`;

const GridProps = css`
  ${gridTemplateColumns};
  ${gridTemplateRows};
  ${gridAutoColumns};
  ${gridAutoRows};
  ${gridAutoFlow};
  ${gridGap};
  ${gridColumn};
  ${gridRow};
  ${gridColumnGap};
  ${gridRowGap};
  ${justifyContent};
  ${alignContent};
`;

const TypographyProps = css`
  ${fontFamily};
  ${fontWeight};
  ${textAlign};
  ${lineHeight};
  ${letterSpacing};
`;

const VisualProps = css`
  ${background};
  ${backgroundImage};
  ${backgroundPosition};
  ${backgroundSize};
  ${backgroundRepeat};
  ${borders};
  ${borderRadius};
  ${borderColor};
  ${boxShadow};
`;

export const Box = styled.div`
  ${CoreProps};
  ${PositioningProps};
  ${BoxModelProps};
  ${FlexboxProps};
  ${GridProps};
  ${TypographyProps};
  ${VisualProps};
`;

export const Grid = styled.div`
  ${CoreProps};
  ${PositioningProps};
  ${BoxModelProps};
  ${FlexboxProps};
  ${GridProps};
  ${TypographyProps};
  ${VisualProps};
`;

Grid.defaultProps = {
  display: "grid"
};

export const FlexCol = styled.div`
  ${CoreProps};
  ${PositioningProps};
  ${BoxModelProps};
  ${FlexboxProps};
  ${GridProps};
  ${TypographyProps};
  ${VisualProps};
`;

FlexCol.defaultProps = {
  display: "flex",
  flexDirection: "column"
};

export const FlexRow = styled.div`
  ${CoreProps};
  ${PositioningProps};
  ${BoxModelProps};
  ${FlexboxProps};
  ${GridProps};
  ${TypographyProps};
  ${VisualProps};
`;

FlexRow.defaultProps = {
  display: "flex",
  flexDirection: "row"
};

const unitType = "px";

const gutterXRight = style({
  prop: "gutterX",
  cssProperty: "marginRight",
  key: "space",
  transformValue: n => -stripUnit(n) / 2 + unitType
});

const gutterXLeft = style({
  prop: "gutterX",
  cssProperty: "marginLeft",
  key: "space",
  transformValue: n => -stripUnit(n) / 2 + unitType
});

const childrenGutterXRight = style({
  prop: "gutterX",
  cssProperty: "paddingRight",
  key: "space",
  transformValue: n => stripUnit(n) / 2 + unitType
});

const childrenGutterXLeft = style({
  prop: "gutterX",
  cssProperty: "paddingLeft",
  key: "space",
  transformValue: n => stripUnit(n) / 2 + unitType
});

const gutterYTop = style({
  prop: "gutterY",
  cssProperty: "marginTop",
  key: "space",
  transformValue: n => -stripUnit(n) / 2 + unitType
});

const gutterYBottom = style({
  prop: "gutterY",
  cssProperty: "marginBottom",
  key: "space",
  transformValue: n => -stripUnit(n) / 2 + unitType
});

const childrenGutterYTop = style({
  prop: "gutterY",
  cssProperty: "paddingTop",
  key: "space",
  transformValue: n => stripUnit(n) / 2 + unitType
});

const childrenGutterYBottom = style({
  prop: "gutterY",
  cssProperty: "paddingBottom",
  key: "space",
  transformValue: n => stripUnit(n) / 2 + unitType
});

export const FlexGrid = styled.div`
  ${CoreProps};
  ${PositioningProps};
  ${BoxModelProps};
  ${FlexboxProps};
  ${VisualProps};
  ${gutterXRight};
  ${gutterXLeft};
  ${gutterYTop};
  ${gutterYBottom};
  > * {
    ${childrenGutterXRight};
    ${childrenGutterXLeft};
    ${childrenGutterYTop};
    ${childrenGutterYBottom};
  }
`;

FlexGrid.defaultProps = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "stretch",
  gutterX: [0],
  gutterY: [0]
};

export const Span = styled.span`
  ${CoreProps};
  ${PositioningProps};
  ${BoxModelProps};
  ${FlexboxProps};
  ${GridProps};
  ${TypographyProps};
  ${VisualProps};
`;
