import React from "react";
import styled, { keyframes } from "styled-components";
import { color } from "styled-system";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

import Text from "../text";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const StyledAccordion = styled(Accordion)`
  ${color};
  .accordion__item {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .accordion__button {
    position: relative;
    width: 100%;
    padding-top: 12px;
    padding-right: 0;
    padding-bottom: 12px;
    padding-left: 0;
    border: none;
    font-size: ${props => props.theme.fontSizes[2] + "px"};
    font-weight: 600;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    &[aria-expanded="true"]::before,
    &[aria-selected="true"]::before {
      transform: rotate(45deg);
    }

    &:focus,
    &:hover {
      outline: none;
      text-decoration: underline;
    }
  }
  .accordion__panel {
    padding-bottom: 20px;
    padding-left: 0;
    animation: ${fadeIn} 350ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

function MiniAccordionGroup({ color, id, items, preExpanded }) {
  return (
    <StyledAccordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      preExpanded={preExpanded}
      color={color}
    >
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + id + index}
          uuid={"accordionGroup" + id + index}
          id={item.id}
        >
          <AccordionItemHeading>
            <AccordionItemButton>{item.heading}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {" "}
            {!item.children && <Text children="Nothing here yet!" />}
            {item.children && item.children}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </StyledAccordion>
  );
}

MiniAccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: []
};

export default MiniAccordionGroup;
