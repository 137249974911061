import React from "react";

import { Wrapper, Comp } from "./style";

const Select = ({ children, ...props }) => (
  <Wrapper>
    <Comp {...props}>{children}</Comp>
  </Wrapper>
);

export default Select;
