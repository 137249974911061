import React from "react";

import PrismicImage from "../prismicImage";

import { Inner, Wrapper } from "./style";

const BASE_IMAGE_SIZE = 54;

const Image = ({ image, size, ...props }) => {
  return (
    <div
      {...props}
      css={`
        width: ${size * BASE_IMAGE_SIZE}px;
        @media (max-width: 1024px) {
          width: ${size * BASE_IMAGE_SIZE - 8}px;
        }
        @media (max-width: 512px) {
          width: ${size * BASE_IMAGE_SIZE - 12}px;
        }
      `}
    >
      <PrismicImage
        image={image}
        alt="festival poster element"
        imgStyle={{ objectFit: "contain" }}
        placeholder={<></>}
        sizes={size >= 2 ? "256px" : "128px"}
      />
    </div>
  );
};

function IlloWrapper({
  illustrations,
  n,
  ne,
  e,
  se,
  s,
  sw,
  w,
  nw,
  size,
  children
}) {
  return (
    <Wrapper>
      {illustrations && illustrations[0] && illustrations[0].illustration && (
        <Inner>
          {n !== -1 && n !== false && (
            <Image
              css={`
                position: absolute;
                top: 0;
                left: 60%;
                transform: translate(-60%, -60%);
              `}
              size={size}
              image={
                illustrations[
                  n >= illustrations.length ? illustrations.length - 1 : n
                ].illustration
              }
            />
          )}
          {ne !== -1 && ne !== false && (
            <Image
              css={`
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(60%, -60%) scaleX(-1);
              `}
              size={size}
              image={
                illustrations[
                  ne >= illustrations.length ? illustrations.length - 1 : ne
                ].illustration
              }
            />
          )}

          {e !== -1 && e !== false && (
            <Image
              css={`
                position: absolute;
                top: 60%;
                right: 0;
                transform: translate(60%, -60%) scaleX(-1);
              `}
              size={size}
              image={
                illustrations[
                  e >= illustrations.length ? illustrations.length - 1 : e
                ].illustration
              }
            />
          )}
          {se !== -1 && se !== false && (
            <Image
              css={`
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translate(60%, 0%) scaleX(-1);
              `}
              size={size}
              image={
                illustrations[
                  se >= illustrations.length ? illustrations.length - 1 : se
                ].illustration
              }
            />
          )}
          {s !== -1 && s !== false && (
            <Image
              css={`
                position: absolute;
                bottom: 0;
                left: 60%;
                transform: translate(-60%, 0%);
              `}
              size={size}
              image={
                illustrations[
                  s >= illustrations.length ? illustrations.length - 1 : s
                ].illustration
              }
            />
          )}
          {sw !== -1 && sw !== false && (
            <Image
              css={`
                position: absolute;
                bottom: 0;
                left: 0;
                transform: translate(-60%, 0%);
              `}
              size={size}
              image={
                illustrations[
                  sw >= illustrations.length ? illustrations.length - 1 : sw
                ].illustration
              }
            />
          )}
          {w !== -1 && w !== false && (
            <Image
              css={`
                position: absolute;
                top: 60%;
                left: 0;
                transform: translate(-60%, -60%);
              `}
              size={size}
              image={
                illustrations[
                  w >= illustrations.length ? illustrations.length - 1 : w
                ].illustration
              }
            />
          )}
          {nw !== -1 && nw !== false && (
            <Image
              css={`
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-60%, -60%);
              `}
              size={size}
              image={
                illustrations[
                  nw >= illustrations.length ? illustrations.length - 1 : nw
                ].illustration
              }
            />
          )}
        </Inner>
      )}
      <span
        css={`
          position: relative;
          z-index: 10;
        `}
      >
        {children}
      </span>
    </Wrapper>
  );
}

IlloWrapper.defaultProps = {
  n: -1,
  ne: -1,
  e: -1,
  se: -1,
  s: -1,
  sw: -1,
  w: -1,
  nw: -1,
  size: 1
};

export default IlloWrapper;
