import React from "react";
import {} from "styled-components/macro";
import * as MaterialIcon from "@mdi/react";

const Icon = ({ symbol, size, color, ...props }) => (
  <MaterialIcon.Icon path={symbol} size={size / 1.5} color={color} {...props} />
);

Icon.defaultProps = {
  symbol: "",
  size: 1
};

export default Icon;
