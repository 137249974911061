import styled from "styled-components";
import { rgba } from "polished";

import PrismicLink from "../prismicLink";

const headerHeight = 160;
const tickerHeight = 24;

export const Wrapper = styled.header`
  position: absolute;
  top: 24px;
  right: 0;
  left: 0;
  z-index: 1200;
  display: flex;
  height: ${headerHeight + "px"};
  /* border-bottom: 1px solid
    ${props => rgba(props.theme.colors.bg.default, 1 / 3)}; */
  pointer-events: none;
  transition: box-shadow 500ms ${props => props.theme.easing},
    transform 500ms ${props => props.theme.easing};

  &.collapsed {
    position: fixed;
    transform: translate3d(0, -96px, 0);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.05);
    top: 0;
  }
`;

export const NavColor = styled.div`
  position: fixed;
  top: 24px;
  right: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: ${headerHeight + "px"};
  margin: 0 auto;
  background-color: transparent;

  &.collapsed {
    background-color: ${props => props.theme.colors.bg.default};
    position: fixed;
    transform: translate3d(0, -96px, 0);
    top: 0;
  }
`;

export const SubnavWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 1100;
  width: 100%;
  margin: 0 auto;
  padding-top: ${headerHeight + "px"};
  background-color: ${props => props.theme.colors.bg.reverse};
  box-shadow: 0 0 80px rgba(0, 0, 0, 0);
  transform: translate3d(0, -100%, 0);
  transition: box-shadow 500ms ${props => props.theme.easing},
    transform 500ms ${props => props.theme.easing};

  &.show {
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.125);
    transform: translate3d(0, 0, 0);
    &.collapsed {
      transform: translate3d(0, -96px, 0);
    }
  }
`;

export const SubnavCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;

  &.show {
    pointer-events: all;
  }
`;

export const WrapperPadding = styled.div`
  height: ${headerHeight + tickerHeight + "px"};
`;

export const NavButton = styled.button`
  position: relative;
  z-index: 3;
  pointer-events: all;
`;

export const NavItem = styled(PrismicLink).attrs({
  activeClassName: "active",
  partiallyActive: true
})`
  position: relative;
  margin: 0 9px;
  font-family: ${props => props.theme.fonts.display};
  font-size: ${props => props.theme.fontSizes[1] + "px"};
  font-weight: 600;
  line-height: 64px;
  vertical-align: middle;
  text-transform: uppercase;
  user-select: none;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    outline: none;
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media not all and (hover: none) {
    &:hover {
      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &.active {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: currentColor;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 250ms ${props => props.theme.easing},
      transform 250ms ${props => props.theme.easing};
  }
`;
