import styled from "styled-components";
import { rgba, triangle } from "polished";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    ${triangle({
      pointingDirection: "bottom",
      width: "10px",
      height: "5px",
      foregroundColor: rgba("#000", 1 / 4)
    })};
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const Comp = styled.select`
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 10px 34px 10px 12px;
  border: 0;
  border-radius: 5px;
  outline: 0;
  font-family: ${props => props.theme.fonts.sans};
  font-size: 16px;
  line-height: 1.5;
  color: ${props => props.theme.colors.text.default};
  background-color: ${props => props.theme.colors.bg.alt};
  cursor: pointer;
  appearance: none;

  &:focus {
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.brand.blue};
  }
  &:active {
    color: ${props => props.theme.colors.text.reverse};
    background-color: ${props => props.theme.colors.brand.blue};
  }

  /* Hide the arrow in IE10 and up */
  &::-ms-expand {
    display: none;
  }
  /* Undo the Firefox inner focus ring */
  &:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;
