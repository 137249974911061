import React from "react";

import { Box, Button, H6, Text, Checkbox, TextInput } from "src/components";

const Start = ({
  phone,
  item,
  consent,
  marketingConsent,
  numberError,
  consentError,
  setPhone,
  setConsent,
  setMarketingConsent,
  setConsentError,
  setNumberError,
  setError,
  setCreate,
  startSchedule
}) => (
  <form
    onSubmit={event => {
      event.preventDefault();
      setConsentError(false);
      setNumberError(false);
      let error = false;
      if (
        phone.replace(/\D/g, "").length !== 10 &&
        phone.replace(/\D/g, "").length !== 11
      ) {
        error = true;
        setNumberError(
          "You must enter a valid phone number (i.e. 1 (403) 555-5555)"
        );
      }
      // if (!consent) {
      //   error = true;
      //   setConsentError(
      //     "You must consent to being contacted to start your schedule."
      //   );
      // }
      if (!error) {
        setError(false);
        startSchedule(phone, item, marketingConsent, true, null, () => {
          setError(true);
        });
      }
    }}
    css={`
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    `}
  >
    <Text
      children={`Your schedule will be linked to your phone number. Once you sign up, that number will receive a text message that contains your code.`}
      mt={5}
      mb={2}
    />
    <Text
      fontWeight={700}
      children={
        "You'll use this phone number and code to access your schedule on any device."
      }
      mb={2}
    />
    <Box my={4}>
      <TextInput
        label={`Phone number`}
        placeholder={`(403) 555-5555`}
        value={phone}
        onChange={event => {
          setPhone(event.target.value);
        }}
      />
      {numberError && <H6 children={numberError} mt={2} ml={4} fontSize={0} />}
      <Box mt={3}>
        {/* <Checkbox
          children={`Yes, I’d like to receive reminders about My Schedule, and important updates from the Festival such as weather warnings.`}
          value={consent}
          onChange={event => {
            setConsent(!consent);
          }}
          checked={consent}
        /> */}
        <Checkbox
          children={`Yes, I’d like to receive notifications from the Festival about important events, promotions, and happenings.`}
          value={marketingConsent}
          onChange={event => {
            setMarketingConsent(!marketingConsent);
          }}
          checked={marketingConsent}
        />
        {consentError && (
          <H6 children={consentError} mt={2} ml={4} fontSize={0} />
        )}
      </Box>
    </Box>
    <Button children={`Next`} variant="primary" width={1} type="submit" />
    <Text
      children={`I consent to the Calgary Folk Music Festival using my phone number to help me build My Schedule and agree to any SMS charges I may incur`}
      mt={2}
      fontSize={0}
      color="text.alt"
    />
    <H6
      children={`Already have a schedule?`}
      mt={4}
      style={{ cursor: "pointer" }}
      onClick={() => {
        setCreate(false);
      }}
    />
  </form>
);

export default Start;
