import React from "react";
import { Link } from "gatsby";

import EmbedModal from "../embedModal";
import { Box } from "../globals";

function PrismicLink({ onClick, link, children, ...props }) {
  return (
    <Box
      as={
        onClick
          ? "div"
          : link.link_type === "Document"
          ? Link
          : link.url &&
            (link.url.indexOf("//airtable.com/embed/") !== -1 ||
              link.url.indexOf("//www.canadahelps.org/") !== -1 ||
              link.url.indexOf("eventbrite.ca/") !== -1 ||
              link.url.indexOf("eventbrite.com/") !== -1)
          ? EmbedModal
          : "a"
      }
      href={link.link_type === "Document" ? undefined : link.url}
      target={
        link.link_type === "Document"
          ? undefined
          : link.url
          ? link.url.indexOf("calgaryfolkfest.com") === -1
            ? "_blank"
            : undefined
          : undefined
      }
      rel={link.link_type === "Document" ? undefined : "noopener noreferrer"}
      to={
        link.link_type_override
          ? link.link_type_override
          : link.link_type === "Document"
          ? link.type === "festival"
            ? "/" + link.uid + "/tickets"
            : link.type === "get_involved"
            ? "/get-involved/volunteer"
            : link.type === "concer"
            ? "/concert/" + link.uid
            : link.type === "event" ||
              link.type == "announcement" ||
              link.type === "post"
            ? "/" + link.type + "/" + link.uid
            : "/" + link.type.replace(/_/g, "-")
          : undefined
      }
      onClick={onClick}
      tabIndex={onClick ? 1 : undefined}
      css={`
        cursor: pointer;
      `}
      {...props}
    >
      {children}
    </Box>
  );
}

export default PrismicLink;
