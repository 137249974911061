import styled, { keyframes } from "styled-components";

const marquee = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to{
    transform: translate3d(-100%, 0, 0);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 24px;
  background-color: ${props => props.theme.colors.bg.reverse};
`;

export const InnerWrapper = styled.div`
  position: absolute;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
`;

export const Marquee = styled.div`
  display: inline-block;
  padding-left: 100%;
  animation: ${marquee} 10s linear infinite
    ${props => (props.delay ? "5s" : "0s")};
`;
