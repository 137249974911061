import React from "react";
import { keyframes } from "styled-components";

import { H6 } from "../../headings";

const slideIn = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const PlaylistComp = ({ source, close }) => (
  <div
    css={`
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;
      transform: translate3d(0, 100%, 0);

      animation: ${slideIn} 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
    `}
  >
    <div
      css={`
        display: flex;
        justify-content: flex-start;
      `}
    >
      <button
        css={`
          min-width: 84px;
          margin: 0;
          padding: 8px;
          border: 0;
          background-color: ${props => props.theme.colors.bg.reverse};
          cursor: pointer;
          &:focus {
            outline: 0;
          }
        `}
        onClick={close}
      >
        <H6 children={`Close`} textAlign="center" color="text.reverse" />
      </button>
    </div>
    <div
      css={`
        padding: 4px;
        background-color: ${props => props.theme.colors.bg.reverse};
      `}
    >
      <iframe
        src={source}
        height="80"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
        css={`
          width: 100%;
          border: 0;
          vertical-align: top;
        `}
      />
    </div>
  </div>
);

PlaylistComp.defaultProps = {
  source: "https://open.spotify.com/embed/playlist/5n7pPhPLvZXYRkFJyVkiju"
};

export default PlaylistComp;
