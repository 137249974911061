import React from "react";
import { Link } from "gatsby";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  H3,
  SectionWrapper,
  Text
} from "src/components";

const ScheduleCallout = ({ festival }) => (
  <SectionWrapper pb={[0, 8]} bg="bg.default">
    <Box
      as="article"
      bg="brand.tertiary"
      css={`
        margin: 0 ${props => "-" + props.theme.margin};
        padding: ${props => props.theme.space[8] + "px"}
          ${props => props.theme.margin};
        ${props => props.theme.mediaQueries.small} {
          margin: 0;
          padding: ${props => props.theme.space[8] + "px"};
        }
      `}
    >
      <FlexGrid gutterX={[7, 9]} gutterY={[5]} alignItems="center">
        {/* <FlexCol width={[1, 1 / 3]}>
          <div />
        </FlexCol> */}
        <FlexCol width={[1]}>
          <H3
            children={`Start a personalized schedule`}
            mb={2}
            color="text.reverse"
          />
          <Text
            children={`Get alerts, print your own schedule, and make sure you see your favorite artists.`}
            mb={4}
            fontSize={[1, 2]}
            color="text.reverseBody"
          />
          <div
            css={`
              display: flex;
              > *:first-child {
                margin-right: ${props => props.theme.space[2] + "px"};
              }
            `}
          >
            <Button
              as={Link}
              children={`Get started`}
              variant="reverseAlt"
              to={"/" + festival + "/schedule?mySchedule=true"}
            />
          </div>
        </FlexCol>
      </FlexGrid>
    </Box>
  </SectionWrapper>
);

export default ScheduleCallout;
