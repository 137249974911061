import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import { Ticker } from "src/components";

import Primary from "./components/primary";
import Subnav from "./components/subnav";
import MobileMenu from "./components/mobileMenu";

function Header({ location, invert, invertLogo }) {
  const [subnav, setSubnav] = useState([]);
  const [mobileMenuPanel, setMobileMenuPanel] = useState("");
  const [mobileMenuUnmounting, setMobileMenuUnmounting] = useState(false);
  const [headerState, setHeaderState] = useState("open");
  const [playlistActive, setPlaylistActive] = useState(false);

  const unmountMenu = () => {
    setMobileMenuUnmounting(true);
    setSubnav([]);
    setMobileMenuPanel("");
    setTimeout(() => {
      setMobileMenuUnmounting(false);
    }, 300);
  };

  useEffect(() => {
    setSubnav([]);
    setMobileMenuPanel("");
  }, [location]);

  useEffect(() => {
    function handleScroll() {
      const doc = document.documentElement;
      if (doc.style.position !== "fixed") {
        const top =
          (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 24 && headerState === "open") {
          setHeaderState("collapsed");
        }
        if (top <= 24 && headerState !== "open") {
          setHeaderState("open");
        }
      }
    }
    function escFunction(event) {
      if (event.keyCode === 27) {
        setSubnav([]);
      }
    }
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <StaticQuery
      query={graphql`
        {
          navigation: prismicNavigation {
            dataString
          }
          activeFestival: prismicActiveFestival {
            dataString
            data {
              ongoing_festival {
                document {
                  ... on PrismicFestival {
                    id
                    data {
                      tagline
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let navigation = JSON.parse(data.navigation.dataString);
        let activeFestival = JSON.parse(data.activeFestival.dataString);
        return (
          <>
            {navigation.ticker_link &&
              navigation.ticker_link.link_type &&
              navigation.ticker_text && (
                <Ticker
                  text={navigation.ticker_text}
                  link={navigation.ticker_link}
                />
              )}
            <Primary
              activeFestival={activeFestival}
              activeFestivalTitle={
                data.activeFestival.data.ongoing_festival &&
                data.activeFestival.data.ongoing_festival.document &&
                data.activeFestival.data.ongoing_festival.document.data &&
                data.activeFestival.data.ongoing_festival.document.data.title &&
                data.activeFestival.data.ongoing_festival.document.data.title
                  .text
              }
              showATF={activeFestival.show_at_the_festival === "Yes"}
              showPYF={activeFestival.show_plan_your_festival === "Yes"}
              showSchedule={activeFestival.show_schedule == "Yes"}
              showSponsors={activeFestival.show_sponsors === "Yes"}
              showLineup={activeFestival.show_lineup === "Yes"}
              showFAQ={activeFestival.show_faq === "Yes"}
              invert={invert}
              invertLogo={invertLogo}
              sponsorLogo={
                navigation.nav_sponsor_logo &&
                navigation.nav_sponsor_logo.url &&
                navigation.nav_sponsor_logo.url
              }
              sponsorLogoLink={
                navigation.sponsor_link &&
                navigation.sponsor_link.url &&
                navigation.sponsor_link.url
              }
              headerState={headerState}
              navigation={navigation.body}
              subnav={subnav}
              setSubnav={setSubnav}
              setMobileMenuPanel={setMobileMenuPanel}
              primaryButtonText={navigation.primary_button_text}
              primaryButtonLink={navigation.primary_button_cta}
              secondaryButtonText={navigation.secondary_button_text}
              secondaryButtonLink={navigation.secondary_button_cta}
              listenLink={navigation.listen_link && navigation.listen_link.url}
              playlistActive={playlistActive}
              setPlaylistActive={setPlaylistActive}
            />
            <Subnav
              headerState={headerState}
              subnav={mobileMenuPanel === "" ? subnav : []}
              setSubnav={setSubnav}
            />
            {(mobileMenuPanel !== "" || mobileMenuUnmounting) && (
              <MobileMenu
                invert={invert}
                invertLogo={invertLogo}
                close={() => {
                  unmountMenu();
                }}
                activeFestival={activeFestival}
                activeFestivalTitle={
                  data.activeFestival.data.ongoing_festival &&
                  data.activeFestival.data.ongoing_festival.document &&
                  data.activeFestival.data.ongoing_festival.document.data &&
                  data.activeFestival.data.ongoing_festival.document.data
                    .title &&
                  data.activeFestival.data.ongoing_festival.document.data.title
                    .text
                }
                showATF={activeFestival.show_at_the_festival === "Yes"}
                showPYF={activeFestival.show_plan_your_festival === "Yes"}
                showSchedule={activeFestival.show_schedule == "Yes"}
                showSponsors={activeFestival.show_sponsors === "Yes"}
                showFAQ={activeFestival.show_faq === "Yes"}
                showLineup={activeFestival.show_lineup === "Yes"}
                navigation={navigation.body}
                subnav={subnav}
                setSubnav={setSubnav}
                unmounting={mobileMenuUnmounting}
                primaryButtonText={navigation.primary_button_text}
                primaryButtonLink={navigation.primary_button_cta}
                secondaryButtonText={navigation.secondary_button_text}
                secondaryButtonLink={navigation.secondary_button_cta}
                listenLink={
                  navigation.listen_link && navigation.listen_link.url
                }
                playlistActive={playlistActive}
                setPlaylistActive={setPlaylistActive}
              />
            )}
          </>
        );
      }}
    />
  );
}

export default Header;
