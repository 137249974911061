import React from "react";
import styled, { keyframes } from "styled-components";
import { color } from "styled-system";
import { cover } from "polished";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

import { H6 } from "../headings";
import PrismicImage from "../prismicImage";
import Text from "../text";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const StyledAccordion = styled(Accordion)`
  ${color};
  .accordion__item {
    ${color};
    border-bottom: 1px solid currentColor;
    &:first-child {
      border-top: 1px solid currentColor;
    }
  }
  .accordion__button {
    position: relative;
    width: 100%;
    padding-top: 32px;
    padding-right: 0;
    padding-bottom: 32px;
    padding-left: 48px;
    border: none;
    font-size: ${props => props.theme.fontSizes[2] + "px"};
    font-weight: 400;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    ${props => props.theme.mediaQueries.small} {
      padding-left: 64px;
    }

    &[aria-expanded="true"],
    &[aria-selected="true"] {
      > div {
        opacity: 0;
        /* transform: translateY(8px); */
      }
    }

    &[aria-expanded="true"]::before,
    &[aria-selected="true"]::before {
      transform: rotate(45deg);
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    ${props => props.theme.mediaQueries.small} {
      padding-left: 80px;
      font-size: ${props => props.theme.fontSizes[3] + "px"};
    }
  }
  .accordion__panel {
    padding-bottom: 32px;
    padding-left: 0;
    animation: ${fadeIn} 350ms cubic-bezier(0.4, 0, 0.2, 1);
    ${props => props.theme.mediaQueries.small} {
      padding-left: 80px;
    }
  }
`;

const Control = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  ${color};
  transform: translateY(-50%);
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: translateY(-50%);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: translateY(-50%) rotate(90deg);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  ${StyledAccordion} .accordion__button[aria-expanded="true"] &::after {
    transform: translateY(-50%) rotate(90deg) scaleX(0);
  }
  ${props => props.theme.mediaQueries.small} {
    left: 16px;
    width: 32px;
    height: 32px;
  }
`;

const GraphicContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 90px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

function AccordionGroup({ color, id, items, preExpanded }) {
  return (
    <StyledAccordion
      allowZeroExpanded={true}
      preExpanded={preExpanded}
      color={color}
    >
      {items.map((item, index) => (
        <AccordionItem
          key={"accordionGroup" + id + index}
          uuid={"accordionGroup" + id + index}
          id={item.id}
        >
          <AccordionItemHeading>
            <AccordionItemButton style={item.image && { paddingRight: "98px" }}>
              <Control color={color} />
              {item.heading}
              {item.subheading && (
                <H6
                  children={item.subheading}
                  css={`
                    margin-top: 8px;
                    color: inherit;
                    opacity: 0.5;
                  `}
                />
              )}
              {item.image && (
                <GraphicContainer>
                  <PrismicImage
                    image={item.image && item.image.url && item.image}
                    style={{ ...cover(), width: "100%", height: "100%" }}
                  />
                </GraphicContainer>
              )}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {!item.children && <Text children="Nothing here yet!" />}
            {item.children && item.children}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </StyledAccordion>
  );
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: []
};

export default AccordionGroup;
