import React from "react";
import { Link } from "gatsby";
import { mdiChevronDown, mdiMenu, mdiMusic } from "@mdi/js";

import {
  FlexCol,
  FlexGrid,
  FlexRow,
  Box,
  H6,
  PrismicLink,
  SmallButton,
} from "src/components";

import Icon from "../../icon";

import { Wrapper, NavItem, NavColor } from "../style";

import fullLogo from "../../../../static/logos/fullDark.png";
import fullLogoInvert from "../../../../static/logos/fullLight.png";

import smallLight from "../../../../static/logos/smallLight.png";
import smallDark from "../../../../static/logos/smallDark.png";
import PlaylistComp from "./playlistComp";

const NAV_ITEM_AVG_WIDTH = 175;
const headerHeight = 160;

function Primary({
  activeFestival,
  activeFestivalTitle,
  showATF,
  showPYF,
  showSchedule,
  showSponsors,
  showLineup,
  showFAQ,
  sponsorLogo,
  sponsorLogoLink,
  headerState,
  navigation,
  subnav,
  setSubnav,
  setMobileMenuPanel,
  primaryButtonLink,
  primaryButtonText,
  secondaryButtonLink,
  secondaryButtonText,
  invert,
  invertLogo,
  listenLink,
  playlistActive,
  setPlaylistActive,
}) {
  return (
    <>
      <NavColor
        className={subnav.length > 0 ? "hide " + headerState : headerState}
        id="headerColor"
      />
      <Wrapper
        className={subnav.length > 0 ? "hide " + headerState : headerState}
      >
        <div
          css={`
            width: 100%;
            margin: 0 auto;
            padding: 0 ${(props) => props.theme.margin};
          `}
        >
          <div
            css={`
              display: flex;
              width: 100%;
              max-width: ${(props) => props.theme.maxWidth[2]};
              height: ${headerHeight + "px"};
              margin: 0 auto;
            `}
          >
            <FlexRow flex={1}>
              <Box
                css={`
                  width: auto;
                  pointer-events: all;
                  position: relative;
                  ${(props) => props.theme.mediaQueries.small} {
                    width: ${headerHeight + "px"};
                  }
                `}
              >
                <Link
                  title="home"
                  to="/"
                  css={`
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: inline-block;
                    margin: 24px 0;
                    transition: opacity 200ms ${(props) => props.theme.easing};
                  `}
                  style={{
                    opacity: headerState === "collapsed" ? 0 : 1,
                    pointerEvents: headerState === "collapsed" ? "none" : "all",
                  }}
                >
                  <img
                    src={
                      subnav.length > 0
                        ? fullLogoInvert
                        : invertLogo
                        ? fullLogoInvert
                        : fullLogo
                    }
                    style={{
                      width: (headerHeight - 48) * (58 / 75) + "px",
                      height: headerHeight - 48 + "px",
                      verticalAlign: "top",
                    }}
                    invert={invert}
                    alt="Calgary Folk Fest Logo"
                  />
                </Link>
                <Link
                  title="home"
                  to="/"
                  css={`
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: inline-block;
                    margin: 20px 0;
                    transition: opacity 200ms ${(props) => props.theme.easing};
                  `}
                  style={{
                    opacity: headerState === "collapsed" ? 1 : 0,
                    pointerEvents: headerState === "collapsed" ? "all" : "none",
                  }}
                >
                  <img
                    src={subnav.length > 0 ? smallLight : smallDark}
                    style={{
                      width: (64 - 40) * (55 / 25) + "px",
                      height: 64 - 40 + "px",
                      verticalAlign: "top",
                    }}
                    invert={invert}
                    alt="Calgary Folk Fest Logo Compressed"
                  />
                </Link>
              </Box>
              <FlexCol
                alignItems="flex-end"
                ml="auto"
                css={`
                  @media (max-width: ${navigation.length *
                    NAV_ITEM_AVG_WIDTH}px) {
                    display: none;
                  }
                `}
              >
                <div
                  css={`
                    width: 100%;
                    margin-top: 24px;
                  `}
                >
                  <FlexGrid
                    justifyContent="space-between"
                    alignItems="center"
                    gutterX={[2]}
                  >
                    {sponsorLogo && (
                      <div>
                        <Box
                          p={1}
                          mr="auto"
                          ml={-2}
                          bg="#fff"
                          css={`
                            z-index: 3;
                            position: relative;
                            pointer-events: all;
                            overflow: hidden;
                          `}
                        >
                          <FlexRow alignItems="center" pl={2}>
                            <H6 children={`Presented by`} color="text.alt" />

                            <a
                              href={sponsorLogoLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label="View sponsor site"
                            >
                              <div
                                css={`
                                  height: 32px;
                                  margin-left: 8px;
                                `}
                              >
                                <img
                                  src={sponsorLogo}
                                  alt="ATB logo"
                                  css={`
                                    width: auto;
                                    height: 100%;
                                    vertical-align: top;
                                    object-fit: contain;
                                  `}
                                />
                              </div>
                            </a>
                          </FlexRow>
                        </Box>
                      </div>
                    )}
                    <div
                      css={`
                        // margin-left: auto;
                        z-index: 3;
                        position: relative;
                        pointer-events: all;
                      `}
                    >
                      <FlexGrid gutterX={[1]}>
                        {listenLink && (
                          <Box>
                            <SmallButton
                              children={`Listen`}
                              iconLeft={mdiMusic}
                              as={PrismicLink}
                              link={{}}
                              variant={invert ? "transparent" : "default"}
                              onClick={() => {
                                setPlaylistActive(true);
                              }}
                            />
                          </Box>
                        )}
                        {secondaryButtonText && secondaryButtonLink && (
                          <Box>
                            <SmallButton
                              children={secondaryButtonText}
                              as={PrismicLink}
                              link={secondaryButtonLink}
                              variant={invert ? "transparent" : "default"}
                            />
                          </Box>
                        )}
                        {primaryButtonText && primaryButtonLink && (
                          <Box>
                            <SmallButton
                              children={primaryButtonText}
                              as={PrismicLink}
                              link={primaryButtonLink}
                              variant={invert ? "reverseAlt" : "primary"}
                            />
                          </Box>
                        )}
                      </FlexGrid>
                    </div>
                  </FlexGrid>
                </div>
                <FlexRow justifyContent="space-between" width={1} mt="auto">
                  <FlexRow justifyContent="flex-start" ml="auto">
                    {activeFestival &&
                      activeFestival.ongoing_festival &&
                      activeFestival.ongoing_festival.uid && (
                        <NavItem
                          link={{ link_type: "document" }}
                          onClick={() => {
                            if (subnav.length === 0) {
                              setSubnav([
                                {
                                  label: "about",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/",
                                },
                                showLineup && {
                                  label: "Lineup",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/lineup",
                                },

                                showATF && {
                                  label: "At The Festival",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/at-the-festival",
                                },
                                showSchedule && {
                                  label: "Schedule",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/schedule",
                                },

                                showPYF && {
                                  label: "Plan Your Festival",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/plan",
                                },

                                {
                                  label: "Tickets",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/tickets",
                                },
                                // TEMP CHANGE FOR SUMMER SERENADES
                                // showPYF && {
                                //   label: "Accomodations",
                                //   to:
                                //     "/" +
                                //     activeFestival.ongoing_festival.uid +
                                //     "/plan#find-a-place-to-stay",
                                // },
                                showFAQ && {
                                  label: "FAQ",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/faq",
                                },
                                showSponsors && {
                                  label: "Sponsors",
                                  to:
                                    "/" +
                                    activeFestival.ongoing_festival.uid +
                                    "/sponsors",
                                },
                                // TEMP CHANGE FOR SUMMER SERENADES
                                // {
                                //   label: "Past Lineups",
                                //   to: "/past-lineups/",
                                // },
                                {},
                              ]);
                            } else {
                              setSubnav([]);
                            }
                          }}
                          color={
                            subnav.length > 0
                              ? "text.reverse"
                              : headerState === "collapsed"
                              ? "text.default"
                              : invert
                              ? "text.reverse"
                              : "text.default"
                          }
                          style={{
                            zIndex: 3,
                            position: "relative",
                            pointerEvents: "all",
                          }}
                        >
                          {activeFestivalTitle}
                          <Icon
                            symbol={mdiChevronDown}
                            css={`
                              width: 16px !important;
                              height: 16px !important;
                              margin-top: -2px;
                              margin-left: 1px;
                              vertical-align: middle;
                              fill: currentColor;
                              transform-origin: center;
                              transform: rotate(0);
                              transition: transform 250ms
                                ${(props) => props.theme.easing};
                            `}
                          />
                        </NavItem>
                      )}
                    {navigation.map((navItem, index) => {
                      if (navItem.slice_type === "nav_item") {
                        return (
                          <NavItem
                            key={"navItem" + index}
                            link={
                              navItem.primary.linked_page.type === "festival"
                                ? {
                                    link_type_override:
                                      navItem.primary.linked_page.uid,
                                    link_type: "Document",
                                  }
                                : navItem.primary.linked_page
                            }
                            onClick={
                              navItem.primary.linked_page.type ===
                              "get_involved"
                                ? () => {
                                    if (subnav.length === 0) {
                                      setSubnav([
                                        {
                                          label: "Volunteer",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/volunteer",
                                        },
                                        {
                                          label: "Sponsor",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/sponsor",
                                        },
                                        {
                                          label: "Artists",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/artists",
                                        },
                                        {
                                          label: "Vendors",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/vendors",
                                        },
                                        {
                                          label: "Donate",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/donate",
                                        },
                                        // {
                                        //   label: "Insiders' Club",
                                        //   to:
                                        //     "/" +
                                        //     navItem.primary.linked_page.slug +
                                        //     "/insiders-club",
                                        // },
                                        {
                                          label: "Media",
                                          to:
                                            "/" +
                                            navItem.primary.linked_page.slug +
                                            "/media",
                                        },
                                      ]);
                                    } else {
                                      setSubnav([]);
                                    }
                                  }
                                : undefined
                            }
                            color={
                              subnav.length > 0
                                ? "text.reverse"
                                : headerState === "collapsed"
                                ? "text.default"
                                : invert
                                ? "text.reverse"
                                : "text.default"
                            }
                            style={{
                              zIndex: 3,
                              position: "relative",
                              pointerEvents: "all",
                            }}
                          >
                            {navItem.primary.title}
                            {navItem.primary.linked_page.type ===
                              "get_involved" && (
                              <Icon
                                symbol={mdiChevronDown}
                                css={`
                                  width: 16px !important;
                                  height: 16px !important;
                                  margin-top: -2px;
                                  margin-left: 1px;
                                  vertical-align: middle;
                                  fill: currentColor;
                                  transform-origin: center;
                                  transform: rotate(0);
                                  transition: transform 250ms
                                    ${(props) => props.theme.easing};
                                `}
                              />
                            )}
                          </NavItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </FlexRow>
                </FlexRow>
              </FlexCol>
              <FlexCol
                alignItems="flex-end"
                ml="auto"
                css={`
                  display: none;
                  @media (max-width: ${navigation.length *
                    NAV_ITEM_AVG_WIDTH}px) {
                    display: flex;
                  }
                `}
              >
                <FlexCol
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flex={1}
                  css={`
                    z-index: 3;
                    position: relative;
                    pointer-events: all;
                    width: 100%;
                    margin-top: 24px;
                  `}
                >
                  {sponsorLogo && (
                    <div>
                      <FlexRow alignItems="center" height="32px">
                        <H6
                          children={`Presented by`}
                          textAlign="right"
                          color={invert ? "text.reverse" : "text.alt"}
                        />
                        <a
                          href={sponsorLogoLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="View sponsor site"
                        >
                          <div
                            css={`
                              height: 24px;
                              margin-left: 8px;
                            `}
                          >
                            <img
                              src={sponsorLogo}
                              alt="ATB logo"
                              css={`
                                width: auto;
                                height: 100%;
                                vertical-align: top;
                              `}
                            />
                          </div>
                        </a>
                      </FlexRow>
                    </div>
                  )}
                  <button
                    css={`
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      height: 64px;
                      margin-top: auto;
                      margin-right: -7.5vw;
                      padding-right: 7.5vw;
                      padding-left: 16px;
                      border: 0;
                      background-color: transparent;
                      cursor: pointer;
                      pointer-events: all;
                      &:focus {
                        outline: 0;
                      }
                    `}
                    onClick={() => {
                      setMobileMenuPanel("root");
                    }}
                  >
                    <H6
                      children={`Menu`}
                      color={
                        headerState === "collapsed"
                          ? "text.default"
                          : invert
                          ? "text.reverse"
                          : "text.default"
                      }
                    />
                    <Icon
                      symbol={mdiMenu}
                      css={`
                        width: 24px !important;
                        height: 24px !important;
                        margin-left: 8px;
                        vertical-align: middle;
                      `}
                      color={
                        headerState === "collapsed"
                          ? "#000"
                          : invert
                          ? "#fff"
                          : "#000"
                      }
                    />
                  </button>
                </FlexCol>
              </FlexCol>
            </FlexRow>
          </div>
        </div>
      </Wrapper>
      {playlistActive && (
        <PlaylistComp
          source={listenLink}
          close={() => {
            setPlaylistActive(false);
          }}
        />
      )}
    </>
  );
}

export default Primary;
