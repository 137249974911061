import React from "react";

import { Box, Button, H6, Text, TextInput } from "src/components";

const Retrieve = ({
  phone,
  item,
  setPhone,
  numberError,
  setNumberError,
  setError,
  setRetrieve,
  startSchedule
}) => (
  <Box my={[5, 6]}>
    <form
      onSubmit={event => {
        event.preventDefault();
        setNumberError(false);
        let error = false;
        if (
          phone.replace(/\D/g, "").length !== 10 &&
          phone.replace(/\D/g, "").length !== 11
        ) {
          error = true;
          setNumberError(
            "You must enter a valid phone number (i.e. 1 (403) 555-5555)"
          );
        }
        if (!error) {
          setError(false);
          startSchedule(phone, item, undefined, false, null, () => {
            setError(true);
          });
        }
      }}
      css={`
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
      `}
    >
      <Text
        children={`To access your schedule on this device, enter the phone number you used to sign-up.`}
      />
      <Box my={4}>
        <TextInput
          label={`Phone number`}
          placeholder={`(403) 555-5555`}
          type="text"
          value={phone}
          onChange={event => {
            setPhone(event.target.value);
          }}
        />
        {numberError && <H6 mt={2} ml={4} size={0} children={numberError} />}
      </Box>
      <Button
        children={"Access schedule"}
        type="submit"
        width={1}
        variant="primary"
      />
      <Text
        mt={1}
        fontSize={0}
        color="text.alt"
        children={`By using this service you agree to incur any SMS charges.`}
      />
      <H6
        children={`Don't have a schedule yet?`}
        mt={4}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setRetrieve(false);
        }}
      />
    </form>
  </Box>
);

export default Retrieve;
