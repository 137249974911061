import styled from "styled-components";
import { cover, rgba } from "polished";
import { background, color } from "styled-system";

import { buttonStyles } from "src/utils";

const size = 40;
const borderWidth = 1;
const borderRadii = 9999;

export const Wrapper = styled.button`
  position: relative;
  display: inline-block;
  width: ${size + "px"};
  height: ${size + "px"};
  margin: 0;
  padding: 0;

  border-width: ${borderWidth + "px"};
  border-style: solid;
  border-radius: ${borderRadii + "px"};
  text-decoration: none;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: 600;
  line-height: ${size - borderWidth * 2 + "px"};
  text-align: center;
  vertical-align: top;
  ${buttonStyles};
  cursor: pointer;
  overflow: hidden;
  appearance: none;
  user-select: none;
  ${background};
  ${color};
  transition: color 200ms ease-out, background-color 200ms ease-out;

  &:focus {
    outline: none;
    &::after {
      opacity: 1;
    }
  }

  &:active {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    ${cover()};
    background-color: ${props => rgba(props.theme.colors.bg.reverse, 0.1)};
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-out;
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em !important;
    height: 1.5em !important;
    fill: currentColor;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;
