import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { RichText } from "prismic-reactjs";

import { SEO, Footer, Header, Theme } from "src/components";

import { ScheduleProvider } from "src/contexts/schedule";

// Import global css and page transition wrapper
import { GlobalStyles, TransitionWrapper } from "./style";

// Import custom fonts
import "./fonts.css";
import "./print.css";

//polyfills
import "url-search-params-polyfill";

function Layout({ children, location }) {
  const [isMounted, setMounted] = useState(false);
  const [invert, setInvert] = useState(false);
  const [invertLogo, setInvertLogo] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");

  useEffect(() => {
    if (location.pathname !== currentLocation.pathname) {
      setCurrentLocation(location.pathname);
      setMounted(false);
      setTimeout(() => {
        setMounted(true);
      }, 20);
    }
  }, [location]);

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          prismicSettings {
            dataString
          }
        }
      `}
      render={data => {
        const dataParsed = JSON.parse(data.prismicSettings.dataString);
        return (
          <ScheduleProvider>
            <ThemeProvider
              theme={Theme({
                primary: dataParsed.brand_primary_color,
                secondary: dataParsed.brand_secondary_color,
                tertiary: dataParsed.brand_tertiary_color
              })}
            >
              <>
                <SEO
                  description={
                    dataParsed.opengraph_description &&
                    RichText.asText(dataParsed.opengraph_description)
                  }
                  image={
                    dataParsed.opengraph_image && dataParsed.opengraph_image.url
                  }
                  imageAlt={
                    dataParsed.opengraph_image && dataParsed.opengraph_image.alt
                  }
                >
                  {process.env.GATSBY_PREVIEW_ACTIVE && (
                    <>
                      <script type="text/javascript">
                        {`window.prismic = 
                                { endpoint: 'https://` +
                          process.env.GATSBY_PRISMIC_REPO +
                          `.prismic.io/api/v2'
                                };`}
                      </script>
                      <script
                        type="text/javascript"
                        src="//static.cdn.prismic.io/prismic.min.js"
                      />
                    </>
                  )}
                </SEO>
                <GlobalStyles />
                <Header
                  location={location}
                  invert={invert}
                  invertLogo={invertLogo}
                />
                <TransitionWrapper className={isMounted && "mounting"}>
                  <main
                    css={`
                      overflow: hidden;
                    `}
                  >
                    {React.cloneElement(children, {
                      setInvert: setInvert,
                      setInvertLogo: setInvertLogo
                    })}
                  </main>
                  <Footer />
                </TransitionWrapper>
              </>
            </ThemeProvider>
          </ScheduleProvider>
        );
      }}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
