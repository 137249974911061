import React, { useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color } from "styled-system";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { H1, H6, PrismicImage, IlloWrapper, Text } from "src/components";

const headerHeight = 160;
const tickerHeight = 24;

const Wrapper = styled.header`
  padding-top: ${tickerHeight + headerHeight + "px"};
  ${color};
`;

function PageHeader({
  color,
  heading,
  subheading,
  image,
  invert,
  links,
  setInvert,
  setInvertLogo,
  illustrations,
  children,
  ...props
}) {
  useEffect(() => {
    setInvert(invert);
    setInvertLogo(invert);
  }, []);

  return (
    <Wrapper bg={color}>
      {heading && (
        <section
          css={`
            padding: 0 ${(props) => props.theme.margin};
          `}
        >
          <div
            css={`
              width: 100%;
              max-width: ${(props) => props.theme.maxWidth[1]};
              margin: 0 auto;
              padding: ${(props) => props.theme.space[8] + "px"} 0
                ${(props) => props.theme.space[5] + props.theme.space[2] + "px"};
            `}
          >
            {heading && (
              <H1
                children={heading}
                textAlign="center"
                color={invert ? "text.reverse" : "text.default"}
              />
            )}
            {subheading && (
              <Text
                fontSize={[16, 18]}
                children={subheading}
                textAlign="center"
                color={invert ? "text.reverse" : "text.default"}
                mt={16}
              />
            )}
            {links && (
              <div
                css={`
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  margin-top: ${(props) => props.theme.space[5] + "px"};
                  > * {
                    margin: ${(props) => props.theme.space[2] + "px"}
                      ${(props) => props.theme.space[2] + "px"};
                  }
                  ${(props) => props.theme.mediaQueries.small} {
                    > * {
                      margin: ${(props) => props.theme.space[2] + "px"}
                        ${(props) => props.theme.space[3] + "px"};
                    }
                  }
                `}
              >
                <H6
                  children={`Jump to`}
                  color={invert ? "text.reverseAlt" : "text.alt"}
                />
                {links.map((link, index) => {
                  if (link) {
                    return (
                      <H6 color={invert ? "text.reverse" : "text.default"}>
                        {typeof link === "object" ? (
                          <Link
                            children={link.label}
                            to={link.href}
                            key={link.label + index}
                            offset={128}
                            css={`
                              border-bottom: 2px solid transparent;
                              color: inherit;
                              &:hover {
                                border-bottom: 2px solid currentColor;
                              }
                            `}
                          />
                        ) : (
                          <AnchorLink
                            children={link}
                            href={"#" + link.replace(/\s/g, "-").toLowerCase()}
                            key={link + index}
                            offset={128}
                            css={`
                              border-bottom: 2px solid transparent;
                              color: inherit;
                              &:hover {
                                border-bottom: 2px solid currentColor;
                              }
                            `}
                          />
                        )}
                      </H6>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
            {children}
          </div>
        </section>
      )}
      {image && (
        <section
          css={`
            position: relative;
            z-index: 0;
            padding: 0 ${(props) => props.theme.margin};
            // Splits the image with a colored top and white bottom.
            &::after {
              content: "";
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: -1;
              height: 50%;
              background-color: ${(props) => props.theme.colors.bg.default};
            }
          `}
        >
          <div
            css={`
              width: 100%;
              max-width: ${(props) => props.theme.maxWidth[0]};
              margin: 0 auto;
            `}
          >
            {illustrations && (
              <IlloWrapper illustrations={illustrations} {...props}>
                <PrismicImage image={image} sizes="50vw" />
              </IlloWrapper>
            )}
            {!illustrations && <PrismicImage image={image} sizes="50vw" />}
          </div>
        </section>
      )}
    </Wrapper>
  );
}

PageHeader.propTypes = {
  color: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.string,
  invert: PropTypes.bool.isRequired,
  links: PropTypes.array,
  setInvert: PropTypes.func.isRequired,
  setInvertLogo: PropTypes.func.isRequired,
};

export default PageHeader;
