import React from "react";

import PrismicImage from "../prismicImage";

const Illustration = ({ illustrations, number, size, css, ...props }) => {
  if (
    illustrations &&
    illustrations.length > 0 &&
    number !== false &&
    number !== -1
  ) {
    return (
      <div
        {...props}
        css={
          `
          width: ${size * 64}px;
        ` + css
        }
      >
        <PrismicImage
          image={
            illustrations[
              number >= illustrations.length ? illustrations.length - 1 : number
            ].illustration
          }
          alt="festival poster element"
          imgStyle={{ objectFit: "contain" }}
          placeholder={<></>}
          sizes={size >= 2 ? "256px" : "128px"}
        />
      </div>
    );
  } else {
    return null;
  }
};

Illustration.defaultProps = {
  illustrations: [],
  number: -1
};

export default Illustration;
