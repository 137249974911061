import React from "react";
import styled, { css } from "styled-components";
import {
  color,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  maxWidth,
  minWidth,
  space,
  textAlign,
  width
} from "styled-system";

const changeables = css`
  ${width};
  ${maxWidth};
  ${minWidth};
  ${space};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${letterSpacing};
  ${lineHeight};
  ${color};
`;

const Text = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
    textAlign,
    color,
    bg,
    ...props
  }) => <p {...props} />
)`
  ${changeables};
  line-height: 1.4;
`;

Text.defaultProps = {
  m: 0,
  fontSize: 2,
  color: "text.default"
};

export default Text;
