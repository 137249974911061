import React from "react";
import PropTypes from "prop-types";

import Icon from "../icon";

import { Wrapper, Label, Comp } from "./style";

const TextInput = ({ iconLeft, iconRight, label, ...props }) => (
  <Wrapper iconLeft={iconLeft} iconRight={iconRight}>
    {label && <Label children={label} />}
    <div
      css={`
        position: relative;
      `}
    >
      {iconLeft && <Icon symbol={iconLeft} />}
      {iconRight && <Icon symbol={iconRight} />}
      <Comp iconLeft={iconLeft} iconRight={iconRight} {...props} />
    </div>
  </Wrapper>
);

TextInput.propTypes = {
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  label: PropTypes.string
};

export default TextInput;
