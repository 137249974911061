import React from "react";

import { Wrapper, Input, Control } from "./style";

const Radio = ({ children, ...props }) => (
  <Wrapper>
    <Input {...props} />
    <Control />
    {children}
  </Wrapper>
);

export default Radio;
