import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  imageAlt,
  children,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description && description;
        const setOG = metaDescription || image;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title + " | " + data.site.siteMetadata.title}
            // titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={(setOG
              ? [
                  {
                    name: `description`,
                    content: metaDescription,
                  },
                  {
                    property: `og:title`,
                    content: title + " | " + data.site.siteMetadata.title,
                  },
                  {
                    property: `og:description`,
                    content: metaDescription,
                  },
                  {
                    property: `og:type`,
                    content: `website`,
                  },
                  {
                    property: `og:image`,
                    content: image && image,
                  },
                  {
                    property: `og:image:width`,
                    content: `1200`,
                  },
                  {
                    property: `og:image:height`,
                    content: `630`,
                  },
                  {
                    property: `og:image:alt`,
                    content: imageAlt && imageAlt,
                  },
                  {
                    property: `og:image:type`,
                    content: `image/png`,
                  },
                  {
                    name: `twitter:image`,
                    content: image && image,
                  },
                  {
                    property: `twitter:image:alt`,
                    content: imageAlt && imageAlt,
                  },
                  {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                  },
                  {
                    name: `twitter:creator`,
                    content: data.site.siteMetadata.author,
                  },
                  {
                    name: `twitter:title`,
                    content: title + " | " + data.site.siteMetadata.title,
                  },
                  {
                    name: `twitter:description`,
                    content: metaDescription,
                  },
                ]
              : [
                  {
                    property: `og:title`,
                    content: title + " | " + data.site.siteMetadata.title,
                  },
                  {
                    name: `twitter:title`,
                    content: title + " | " + data.site.siteMetadata.title,
                  },
                ]
            )
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            {children}
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

export default SEO;

const detailsQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
