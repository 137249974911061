import styled from "styled-components";
import { cover } from "polished";

export const Wrapper = styled.div`
  position: relative;
`;

export const Inner = styled.div`
  ${cover()};
  z-index: 0;
  pointer-events: none;
  user-select: none;
`;
