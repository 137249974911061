import React, { useState } from "react";
import Marquee from "react-text-marquee";

import PrismicLink from "../prismicLink";
import Text from "../text";

import { Wrapper } from "./style";

function Ticker({ text, link }) {
  const [repeatText] = useState(() => {
    let repeatText = "";
    for (let i = 0; i < 40; i++) {
      repeatText += text + " ";
    }
    return repeatText;
  });

  return (
    <PrismicLink link={link} id="ticker">
      <Wrapper>
        <Text
          color="text.reverse"
          css={`
            font-size: 12px;
            font-weight: 600;
            line-height: 24px;
            vertical-align: middle;
            text-transform: uppercase;
          `}
        >
          <Marquee text={repeatText} loop hoverToStop />
        </Text>
      </Wrapper>
    </PrismicLink>
  );
}

export default Ticker;
