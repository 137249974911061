import styled from "styled-components";
import { color, fontSize, space, textAlign } from "styled-system";
import { cover } from "polished";

const RichText = styled.div`
  ${space};
  ${fontSize};
  ${textAlign};
  ${color};
  line-height: 1.4;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 0.5em;
  }

  h1 {
    font-family: ${props => props.theme.fonts.display};
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }

  h2 {
    font-family: ${props => props.theme.fonts.display};
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }

  h3 {
    font-family: ${props => props.theme.fonts.display};
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }

  h4 {
    font-family: ${props => props.theme.fonts.display};
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  h5 {
    font-family: ${props => props.theme.fonts.display};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  h6 {
    font-family: ${props => props.theme.fonts.display};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  p {
    margin: 0;
    font-size: inherit;
    line-height: 1.6;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  p + p {
    margin-top: 1em;
  }

  a {
    text-decoration: none;
    background-image: ${props =>
      `linear-gradient(to bottom, transparent 92.5%, ${
        props.theme.colors.brand.primary
      } 10%)`};
    background-repeat: no-repeat;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 1.5em;
    line-height: 1.4;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  [data-oembed-type="video"] {
    div {
      position: relative;
      margin: 16px 0;
      padding-bottom: ${(270 / 480) * 100}%;
      iframe {
        ${cover()};
        width: 100%;
        height: 100%;
      }
    }
  }
  .block-img {
    width: 100%;
    img {
      max-width: 100%;
    }
  }
`;

RichText.defaultProps = {
  fontSize: 2,
  color: "text.default"
};

export default RichText;
