import React from "react";

import Icon from "../icon";

import { Wrapper } from "./style";

const Button = ({ children, iconLeft, iconRight, variant, ...props }) => (
  <Wrapper
    iconLeft={iconLeft}
    iconRight={iconRight}
    variant={variant}
    {...props}
  >
    {iconLeft && <Icon symbol={iconLeft} />}
    <span children={children} />
    {iconRight && <Icon symbol={iconRight} />}
  </Wrapper>
);

Button.defaultProps = {
  variant: "default"
};

export default Button;
