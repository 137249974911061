import React from "react";
import { cover } from "polished";

import { PrismicImage } from "src/components";

const Avatar = ({ image }) => (
  <div
    css={`
      width: 30px;
    `}
  >
    <div
      css={`
        position: relative;
        padding-bottom: 100%;
        border-radius: 50%;
        overflow: hidden;
      `}
    >
      {image.url && (
        <PrismicImage
          image={image}
          style={{ ...cover(), width: "100%", height: "100%" }}
        />
      )}
    </div>
  </div>
);

export default Avatar;
