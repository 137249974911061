import React from "react";

import { Box, Button, FlexCol, H6, Text, TextInput } from "src/components";

const Confirm = ({
  setError,
  getSchedule,
  phone,
  item,
  pin,
  retrieve,
  setPin,
  setNeedsPIN,
  startSchedule,
  resetSent,
  setResentSet
}) => (
  <form
    onSubmit={event => {
      event.preventDefault();
      setError(false);
      getSchedule(pin, () => {
        setError(true);
      });
    }}
    css={`
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    `}
  >
    <FlexCol my={4} alignItems="center">
      <Text mt={2}>
        {retrieve && (
          <span>
            You can login with the pin number you previously recieved at the
            number<strong> {phone}</strong>. Don't remember it? Click the "Don't
            have a code" option down below.
          </span>
        )}
        {!retrieve && (
          <span>
            You should receieve a text message to<strong> {phone} </strong>
            containing your pin number shortly.
          </span>
        )}
      </Text>
    </FlexCol>
    <Box my={4}>
      <TextInput
        label={`Login code`}
        placeholder={`Enter code here`}
        value={pin}
        onChange={event => {
          setPin(event.target.value);
        }}
      />
    </Box>
    <Button
      children={"Confirm"}
      variant="primary"
      disabled={pin.length < 6}
      type="submit"
      width={1}
    />
    <H6
      children={`Entered the wrong number?`}
      mt={4}
      style={{ cursor: "pointer" }}
      onClick={() => {
        setNeedsPIN(false);
      }}
    />
    <H6
      children={`Don't have the code?`}
      mt={2}
      style={{ cursor: "pointer" }}
      onClick={() => {
        setError(false);
        startSchedule(
          phone,
          item,
          undefined,
          true,
          () => {
            setResentSet(true);
          },
          () => {
            setError(true);
          }
        );
      }}
    />
    {resetSent && (
      <Text children={`We resent the code. Check your phone.`} mt={1} />
    )}
  </form>
);

export default Confirm;
