import React, { useState } from "react";

import { Box, FlexCol, H4, Text, Panel } from "src/components";

import Loader from "../loader";

import { useSchedule } from "src/contexts/schedule";

import Added from "./components/added.js";
import Trigger from "./components/trigger.js";
import Choose from "./components/choose.js";
import Start from "./components/start.js";
import Confirm from "./components/confirm.js";
import Usage from "./components/usage.js";
import Retrieve from "./components/retrieve.js";

function AddToScheduleWrapper({
  darkMode,
  festival,
  trigger,
  item,
  view,
  emphasized
}) {
  const {
    schedule,
    addToSchedule,
    removeFromSchedule,
    isInSchedule,
    // isNotified,
    // toggleNotified,
    startSchedule,
    getSchedule,
    needsPin,
    retrieve,
    create,
    setCreate,
    setRetrieve,
    processing,
    setNeedsPIN,
    instructionsComplete,
    setInstructionsComplete
  } = useSchedule();
  return (
    <AddToSchedule
      darkMode={darkMode}
      processing={processing}
      festival={festival}
      scheduleStarted={schedule}
      add={() => {
        addToSchedule(item);
      }}
      remove={() => {
        removeFromSchedule(item);
      }}
      added={item && isInSchedule(item) !== -1}
      // notified={item && isNotified(item)}
      // toggleNotified={() => {
      //   toggleNotified(item);
      // }}
      startSchedule={startSchedule}
      needsPin={needsPin}
      getSchedule={getSchedule}
      item={item}
      trigger={trigger}
      retrieve={retrieve}
      create={create}
      setCreate={setCreate}
      setRetrieve={setRetrieve}
      setNeedsPIN={setNeedsPIN}
      instructionsComplete={instructionsComplete}
      setInstructionsComplete={setInstructionsComplete}
      view={view}
      emphasized={emphasized}
    />
  );
}

function AddToSchedule({
  darkMode,
  scheduleStarted,
  add,
  remove,
  added,
  notified,
  toggleNotified,
  startSchedule,
  getSchedule,
  needsPin,
  item,
  trigger,
  retrieve,
  setRetrieve,
  processing,
  create,
  setCreate,
  setNeedsPIN,
  instructionsComplete,
  setInstructionsComplete,
  view,
  emphasized
}) {
  const [resetSent, setResentSet] = useState(false);
  const [error, setError] = useState(false);
  const [numberError, setNumberError] = useState("");
  const [consentError, setConsentError] = useState("");
  const [pin, setPin] = useState("");
  const [consent, setConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [phone, setPhone] = useState("");

  if (scheduleStarted && instructionsComplete) {
    return (
      <Added
        darkMode={darkMode}
        added={added}
        remove={remove}
        view={view}
        add={add}
        emphasized={emphasized}
        toggleNotified={toggleNotified}
      />
    );
  } else {
    return (
      <Panel
        trigger={
          trigger ? (
            trigger
          ) : (
            <Trigger
              darkMode={darkMode}
              added={added}
              remove={remove}
              add={add}
              notified={notified}
              toggleNotified={toggleNotified}
            />
          )
        }
        children={
          <div
            css={`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              overflow-y: scroll;
              overflow-x: hidden;
              -webkit-overflow-scrolling: touch;
            `}
          >
            <FlexCol px={5} py={[5]}>
              <FlexCol width={1} justifyContent="center" alignItems="center">
                <H4
                  children={
                    scheduleStarted
                      ? "How to use your schedule"
                      : retrieve
                      ? "Access your schedule"
                        ? "Access your schedule"
                        : needsPin
                        ? "Enter your code"
                        : "Create your schedule"
                      : ""
                  }
                  textAlign="center"
                />
                {processing && <Loader />}
                {!processing && (
                  <>
                    {!create && !retrieve && !needsPin && !scheduleStarted && (
                      <Choose
                        setRetrieve={setRetrieve}
                        setCreate={setCreate}
                        setNeedsPIN={setNeedsPIN}
                      />
                    )}
                    {needsPin && (
                      <Confirm
                        setError={setError}
                        getSchedule={getSchedule}
                        startSchedule={startSchedule}
                        phone={phone}
                        item={item}
                        pin={pin}
                        setPin={setPin}
                        setNeedsPIN={setNeedsPIN}
                        retrieve={retrieve}
                        resetSent={resetSent}
                        setResentSet={setResentSet}
                      />
                    )}
                    {scheduleStarted && (
                      <Usage
                        darkMode={darkMode}
                        setInstructionsComplete={setInstructionsComplete}
                      />
                    )}
                    {create && !needsPin && !retrieve && !scheduleStarted && (
                      <Start
                        phone={phone}
                        item={item}
                        consent={consent}
                        marketingConsent={marketingConsent}
                        numberError={numberError}
                        consentError={consentError}
                        setPhone={setPhone}
                        setConsent={setConsent}
                        setMarketingConsent={setMarketingConsent}
                        setConsentError={setConsentError}
                        setNumberError={setNumberError}
                        setError={setError}
                        setCreate={setCreate}
                        startSchedule={startSchedule}
                      />
                    )}
                    {retrieve && !needsPin && (
                      <Retrieve
                        phone={phone}
                        item={item}
                        setPhone={setPhone}
                        numberError={numberError}
                        setNumberError={setNumberError}
                        setError={setError}
                        setRetrieve={setRetrieve}
                        startSchedule={startSchedule}
                      />
                    )}
                    {error && (
                      <Box bg="bg.reverse" my={3} maxWidth={420} p={4}>
                        <Text
                          children={
                            needsPin
                              ? "Your code was not correct. Please double check your code."
                              : "We had a problem starting your schedule. If you had a schedule for the Folk Festival or Block Heater in years prior, you will need to start again fresh this year. Please try again, or make a new schedule, and if this issue persists please contact us at website@calgaryfolkfest.com"
                          }
                          color="text.reverse"
                        />
                      </Box>
                    )}
                  </>
                )}
              </FlexCol>
            </FlexCol>
          </div>
        }
      />
    );
  }
}

export default AddToScheduleWrapper;
