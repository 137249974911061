import React from "react";
import { StaticQuery, graphql } from "gatsby";
import {
  mdiFacebookBox,
  mdiTwitter,
  mdiInstagram,
  mdiSnapchat,
  mdiYoutube,
} from "@mdi/js";
import { hideVisually, rgba } from "polished";

import fullLogoInvert from "../../../static/logos/fullLight.png";

import {
  FlexGrid,
  H4,
  Newsletter,
  PrismicLink,
  SectionWrapper,
} from "src/components";

import { Box } from "../globals";
import Icon from "../icon";
import Text from "../text";

const SocialButton = ({ icon, label, link }) => (
  <PrismicLink link={link}>
    <div
      css={`
        width: 40px;
        div {
          transition: transform 250ms ${(props) => props.theme.easing};
        }
        svg {
          transition: opacity 125ms ${(props) => props.theme.easing};
        }
        @media not all and (hover: none) {
          &:hover {
            div {
              transform: scale(0.95);
              transform-origin: center;
            }
            svg {
              opacity: 0.75;
            }
          }
        }
      `}
    >
      <div
        css={`
          position: relative;
          padding-bottom: 100%;
          border-radius: 9999px;
          color: ${(props) => props.theme.colors.text.reverse};
          background-color: ${(props) =>
            rgba(props.theme.colors.bg.default, 0.125)};
          cursor: pointer;
          transition: transform 500ms ${(props) => props.theme.easing};

          svg {
            transition: opacity 350ms ${(props) => props.theme.easing};
          }

          @media not all and (hover: none) {
            &:hover {
              transform: scale(0.9);
              transform-origin: center;

              svg {
                opacity: 0.5;
              }
            }
          }
        `}
      >
        <Icon
          path={icon}
          size={1.25}
          css={`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: currentColor;
          `}
        />
        <Text
          children={label}
          css={`
            ${hideVisually()};
          `}
        />
      </div>
    </div>
  </PrismicLink>
);

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        navigation: prismicNavigation {
          dataString
        }
      }
    `}
    render={(data) => {
      let navigation = JSON.parse(data.navigation.dataString);
      return (
        <footer
          css={`
            padding-bottom: env(safe-area-inset-bottom);
            background-color: ${(props) => props.theme.colors.bg.reverse};
          `}
        >
          {navigation.global_sponsors &&
            navigation.global_sponsors[0] &&
            navigation.global_sponsors[0].logo && (
              <SectionWrapper>
                <Box
                  py={[5, 6]}
                  css={`
                    border-bottom: 1px solid
                      ${(props) => rgba(props.theme.colors.bg.default, 0.1)};
                  `}
                >
                  {navigation.sponsor_title &&
                    navigation.sponsor_title[0] &&
                    navigation.sponsor_title[0].text && (
                      <H4
                        children={navigation.sponsor_title[0].text}
                        color="text.reverse"
                        textAlign="center"
                        mb={[3, 4]}
                      />
                    )}
                  <FlexGrid
                    justifyContent={["center"]}
                    alignItems="center"
                    gutterX={[5, 7]}
                    gutterY={[3]}
                  >
                    {navigation.global_sponsors.map(({ logo }) => (
                      <Box width={[1 / 3, 1 / 3, null, 1 / 5]}>
                        <Box p={1}>
                          <img
                            src={logo.url}
                            height="auto"
                            width="100%"
                            alt={logo.alt}
                          />
                        </Box>
                      </Box>
                    ))}
                  </FlexGrid>
                </Box>
              </SectionWrapper>
            )}
          <SectionWrapper>
            <Box py={[7, 9]}>
              <Box
                pb={5}
                css={`
                  border-bottom: 1px solid
                    ${(props) => rgba(props.theme.colors.bg.default, 0.1)};
                `}
              >
                <FlexGrid
                  justifyContent={["center", "space-between"]}
                  // alignItems="center"
                  gutterX={[5, 7]}
                  gutterY={[3]}
                >
                  <Box width={[1, 1 / 3, 1 / 2, 3 / 5]}>
                    <Box mb={5}>
                      <img
                        src={fullLogoInvert}
                        style={{
                          width: 112 * (58 / 75) + "px",
                          height: 112,
                        }}
                        alt="Folk Fest logo in white"
                      />
                    </Box>
                  </Box>
                  <Box width={[1, 2 / 3, 1 / 2, 2 / 5]}>
                    <Box>
                      <H4
                        children={`Get our newsletter`}
                        color="text.reverse"
                      />
                      <Text
                        children={`Stay up to date on our year-round concert series`}
                        mt={1}
                        fontSize={[1, 2]}
                        color="text.reverseBody"
                      />
                    </Box>
                    <Box mt={3}>
                      <Newsletter />
                    </Box>
                  </Box>
                </FlexGrid>
              </Box>
              <Box pt={5}>
                <FlexGrid
                  justifyContent="space-between"
                  alignItems="center"
                  gutterX={[5, 7]}
                  gutterY={[3]}
                >
                  <Box>
                    <FlexGrid gutterX={[1]}>
                      {navigation.facebook && navigation.facebook.url && (
                        <SocialButton
                          icon={mdiFacebookBox}
                          label={`Facebook`}
                          link={navigation.facebook}
                        />
                      )}
                      {navigation.twitter && navigation.twitter.url && (
                        <SocialButton
                          icon={mdiTwitter}
                          label={`Twitter`}
                          link={navigation.twitter}
                        />
                      )}
                      {navigation.instagram && navigation.instagram.url && (
                        <SocialButton
                          icon={mdiInstagram}
                          label={`Instagram`}
                          link={navigation.instagram}
                        />
                      )}
                      {navigation.snapchat && navigation.snapchat.url && (
                        <SocialButton
                          icon={mdiSnapchat}
                          label={`Snapchat`}
                          link={navigation.snapchat}
                        />
                      )}
                      {navigation.youtube && navigation.youtube.url && (
                        <SocialButton
                          icon={mdiYoutube}
                          label={`YouTube`}
                          link={navigation.youtube}
                        />
                      )}
                    </FlexGrid>
                  </Box>

                  <Text
                    fontSize={0.75}
                    textAlign={["center", "left"]}
                    color="text.reverse"
                  >
                    © {new Date().getFullYear()} Calgary Folk Festival
                  </Text>
                </FlexGrid>
              </Box>
            </Box>
          </SectionWrapper>
        </footer>
      );
    }}
  />
);

export default Footer;
