import React from "react";
import styled, { css } from "styled-components";
import {
  color,
  maxWidth,
  minWidth,
  space,
  textAlign,
  width
} from "styled-system";

const changeables = css`
  ${width};
  ${maxWidth};
  ${minWidth};
  ${space};
  ${textAlign};
  ${color};
`;

export const H1 = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    ...props
  }) => <h1 children={props.children} {...props} />
)`
  ${changeables};
  font-family: ${props => props.theme.fonts.display};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  ${props => props.theme.mediaQueries.small} {
    font-size: 3rem;
  }
`;

H1.defaultProps = {
  m: 0,
  color: "text.default"
};

export const H2 = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    ...props
  }) => <h2 children={props.children} {...props} />
)`
  ${changeables};
  font-family: ${props => props.theme.fonts.display};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  ${props => props.theme.mediaQueries.small} {
    font-size: 2.25rem;
  }
`;

H2.defaultProps = {
  m: 0,
  color: "text.default"
};

export const H3 = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    ...props
  }) => <h3 children={props.children} {...props} />
)`
  ${changeables};
  font-family: ${props => props.theme.fonts.display};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  ${props => props.theme.mediaQueries.small} {
    font-size: 1.5rem;
  }
`;

H3.defaultProps = {
  m: 0,
  color: "text.default"
};

export const H4 = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    ...props
  }) => <h4 children={props.children} {...props} />
)`
  ${changeables};
  font-family: ${props => props.theme.fonts.display};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  ${props => props.theme.mediaQueries.small} {
    font-size: 1.125rem;
  }
`;

H4.defaultProps = {
  m: 0,
  color: "text.default"
};

export const H5 = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    ...props
  }) => <h5 children={props.children} {...props} />
)`
  ${changeables};
  font-family: ${props => props.theme.fonts.display};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  ${props => props.theme.mediaQueries.small} {
    font-size: 1rem;
  }
`;

H5.defaultProps = {
  m: 0,
  color: "text.default"
};

export const H6 = styled(
  ({
    width,
    maxWidth,
    minWidth,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    ...props
  }) => <h6 children={props.children} {...props} />
)`
  ${changeables};
  font-family: ${props => props.theme.fonts.display};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  ${props => props.theme.mediaQueries.small} {
    font-size: 1rem;
  }
`;

H6.defaultProps = {
  m: 0,
  color: "text.default"
};
