import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color, space } from "styled-system";

const Wrapper = styled.section`
  width: 100%;
  padding-right: ${props => props.theme.margin};
  padding-left: ${props => props.theme.margin};
  ${space};
  ${color};
`;

const Inner = styled.div`
  width: 100%;
  max-width: ${props => props.theme.maxWidth[props.maxWidth]};
  margin: 0 auto;
`;

const SectionWrapper = ({ bg, children, maxWidth, pb, pt, py, ...rest }) => (
  <Wrapper bg={bg} pb={pb} pt={pt} py={py} {...rest}>
    <Inner maxWidth={maxWidth}>{children}</Inner>
  </Wrapper>
);

SectionWrapper.propTypes = {
  bg: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SectionWrapper.defaultProps = {
  maxWidth: 1
};

export default SectionWrapper;
