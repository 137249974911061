import { css } from "styled-components";
import { rgba } from "polished";

const DefaultVariant = css`
  color: ${props => props.theme.colors.text.default};
  &::before {
    background-color: ${props => props.theme.colors.bg.default};
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.reverse};
  }
  &::after {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.reverse};
  }
`;

const DefaultAltVariant = css`
  color: ${props => props.theme.colors.text.reverse};
  &::before {
    background-color: ${props => props.theme.colors.bg.reverse};
  }
  &::after {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.reverse};
  }
`;

const ReverseVariant = css`
  color: ${props => props.theme.colors.text.reverse};
  background-color: transparent;
  box-shadow: inset 0 0 0 1px
    ${props => rgba(props.theme.colors.bg.default, 0.25)};
`;

const ReverseAltVariant = css`
  color: ${props => props.theme.colors.text.default};
  &::before {
    background-color: ${props => props.theme.colors.bg.default};
  }
  &::after {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.default};
  }
`;

const TransparentVariant = css`
  color: ${props => props.theme.colors.text.reverse};
  &::before {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.default};
  }
  &::after {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.bg.default};
  }
`;

const PrimaryVariant = css`
  color: ${props => props.theme.colors.text.reverse};
  color: #fff;
  &::before {
    background-color: ${props => props.theme.colors.brand.primary};
  }
  &::after {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.brand.primary};
  }
`;

const FacebookVariant = css`
  color: #fff;
  &::before {
    background-color: #3b5998;
  }
  &::after {
    box-shadow: inset 0 0 0 1px #3b5998;
  }
`;

const TwitterVariant = css`
  color: #fff;
  &::before {
    background-color: #1da1f2;
  }
  &::after {
    box-shadow: inset 0 0 0 1px #1da1f2;
  }
`;

const InstagramVariant = css`
  color: #fff;
  &::before {
    background-color: #e4405f;
  }
  &::after {
    box-shadow: inset 0 0 0 1px #e4405f;
  }
`;

const SnapchatVariant = css`
  color: #222;
  &::before {
    background-color: #fffc00;
  }
  &::after {
    box-shadow: inset 0 0 0 1px #fffc00;
  }
`;

const buttonStyles = css`
  ${props => props.variant === "default" && DefaultVariant};
  ${props => props.variant === "defaultAlt" && DefaultAltVariant};
  ${props => props.variant === "reverse" && ReverseVariant};
  ${props => props.variant === "reverseAlt" && ReverseAltVariant};
  ${props => props.variant === "transparent" && TransparentVariant};
  ${props => props.variant === "primary" && PrimaryVariant};
  ${props => props.variant === "facebook" && FacebookVariant};
  ${props => props.variant === "twitter" && TwitterVariant};
  ${props => props.variant === "instagram" && InstagramVariant};
  ${props => props.variant === "snapchat" && SnapchatVariant};
`;

export default buttonStyles;
