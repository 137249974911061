import React, { useState } from "react";
import LastImage from "react-last-image";
import { mdiInformationOutline } from "@mdi/js";

import Text from "../text";
import Icon from "../icon";
import { Box, FlexCol } from "../globals";

function PrismicImage({ imgStyle, style, image, hideAttribution, ...props }) {
  const [copyrightActive, setCopyRightActive] = useState(false);
  const [images] = useState(() => {
    if (image.url) {
      let images = [];
      images.push({
        url: image.url,
        height: image.dimensions.height,
        width: image.dimensions.width
      });
      if (image.placeholder) {
        images.push({
          url: image.placeholder.url,
          height: image.placeholder.dimensions.height,
          width: image.placeholder.dimensions.width
        });
      }
      if (image.twoThirds) {
        images.push({
          url: image.twoThirds.url,
          height: image.twoThirds.dimensions.height,
          width: image.twoThirds.dimensions.width
        });
      }
      if (image.half) {
        images.push({
          url: image.half.url,
          height: image.half.dimensions.height,
          width: image.half.dimensions.width
        });
      }
      if (image.quarter) {
        images.push({
          url: image.quarter.url,
          height: image.quarter.dimensions.height,
          width: image.quarter.dimensions.width
        });
      }
      if (image.third) {
        images.push({
          url: image.third.url,
          height: image.third.dimensions.height,
          width: image.third.dimensions.width
        });
      }
      return images;
    }
  });
  if (image.url) {
    return (
      <Box position="relative" style={style}>
        <LastImage
          images={images}
          alt={image.alt}
          style={{ width: "100%", height: "100%" }}
          imgStyle={imgStyle}
          {...props}
        />
        {copyrightActive && (
          <FlexCol
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            bg="rgba(0,0,0,0.5)"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              children={"Photo Credit: " + image.copyright}
              color="text.reverse"
            />
          </FlexCol>
        )}
        {image.copyright && !hideAttribution && (
          <FlexCol
            position="absolute"
            right={12}
            bottom={12}
            backgroundColor="black"
            onClick={() => {
              setCopyRightActive(!copyrightActive);
            }}
            cursor="pointer"
            p={2}
          >
            <Icon symbol={mdiInformationOutline} color="white" size={1.5} />
          </FlexCol>
        )}
      </Box>
    );
  } else {
    return null;
  }
}

PrismicImage.defaultProps = {
  hideAttribution: false
};

export default PrismicImage;
